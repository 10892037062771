import { z } from "zod";

// Define Zod schema
const businessSchema = z.object({
    businessName: z.string().nonempty('Business name is required'),
    category: z.string().nonempty('Category is required'),
    subCategory: z.string().nonempty('Sub category is required'),
    country: z.string().nonempty('Country is required'),
    address1: z.string().nonempty('Address is required'),
    address2: z.string().optional(),
    description: z.string().nonempty('Description is required'),
    city: z.string().nonempty('City is required'),
    state: z.string().nonempty('State is required'),
    zipcode: z.string().nonempty('Zipcode is required'),
    phone: z.string().nonempty('Phone number is required'),
    email: z.string().email('Invalid email address').nonempty('Email is required'),
    profile: z.string().nonempty('Logo is required'),
    backgroundImage: z.string().nonempty('Cover image is required'),
    facebook: z.string().optional(),
    twitter: z.string().optional(),
    instagram: z.string().optional(),
});

const businessDefaultValues =  {
    vendorId: '',
    businessName: '',
    category: '',
    subCategory: '',
    country: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipcode: '',
    phone: '',
    email: '',
    profile: '',
    backgroundImage: '',
    description: "",
    facebook: "",
    twitter: "",
    instagram: "",
};

export { businessSchema, businessDefaultValues };