import React from 'react'
import MainHeader from '../../../components/common/header/main-header'
import Footer from '../../../components/common/footer/footer'
import { Link } from 'react-router-dom'

export default function UnlockExclusivePage() {
    return (
        <>
            <MainHeader />

            <main className='w-full relative'>
                <img src="/assets/images/unlock-ex.png" className='w-full' alt="" />
                <div className='absolute top-0 left-0 right-0 2xl:pt-32 xl:pt-28 pt-3'>
                    <div className='max-w-screen-xl mx-auto 2xl:px-0 xl:px-10 px-5'>
                        <h1 className='2xl:text-4xl text-black xl:text-4xl text-sm font-bold 2xl:w-[582px] xl:w-[582px] 2xl:leading-[55px] xl:leading-[55px] w-full'>
                            Unlock exclusive rewards and earn big with our unique system.
                        </h1>
                        <Link to="/magic-pools" type='button' className='w-fit 2xl:text-sm xl:text-sm md:text-sm lg:text-sm text-xs px-6 !my-6 py-2 bg-black text-white rounded-full'>
                            Join the Magic Club Today
                        </Link>
                    </div>
                </div>
            </main>

            {/* ========= section one code start here ======== */}
            <section className='max-w-screen-2xl mx-auto pt-10'>
                <h3 className='text-2xl text-center font-medium'>
                    What is the Magic Club?
                </h3>
                <div className='max-w-screen-xl mx-auto 2xl:px-0 xl:px-2 px-3'>
                    <p className='text-center mt-2'>
                        The Magic Club is an innovative and unique reward system designed to give our customers a truly magical experience. Unlike traditional rewards programs that offer small, incremental benefits, the Magic Club focuses on delivering bulk rewards that create more value, excitement, and delight for our loyal customers.
                    </p>
                </div>
                {/* ========= section one code end here ======== */}
            </section>
            {/* ========= section two code start here ======== */}
            <section className='max-w-screen-xl mx-auto pt-10 2xl:px-0 xl:px-0 px-8'>
                <h3 className='text-2xl text-center font-medium'>
                    How It Works?
                </h3>
                <div className='grid 2xl:grid-cols-2 xl:grid-cols-2 grid-cols-1 mt-8 gap-4 gap-y-8'>
                    <div className='w-full relative group'>
                        <div className='flex justify-center items-center gap-4'>
                            <div className='relative'>
                                <div className='size-5 group-hover:w-[72px] transition-all ease-linear duration-500 group-hover:h-6 top-1 -left-3 bg-primary rounded-full absolute z-10'></div>
                                <h3 className='text-xl font-medium z-50 relative'>
                                    Step1
                                </h3>
                            </div>
                            <div className='flex items-center gap-2'>
                                <img src="/assets/images/svg/club-icon.svg" className='size-5' alt="" />
                                <p className='text-xl font-medium'>
                                    Join the Club
                                </p>
                            </div>
                        </div>
                        <p className='mt-2 text-center'>
                            With every purchase you make on our website, you create a new "Club" — part of our exclusive rewards system! Each Club has the capacity to include up to 20,000+ clubs, unlocking special benefits and rewards as it grows.
                        </p>
                    </div>
                    <div className='w-full relative group'>
                        <div className='flex justify-center items-center gap-4'>
                            <div className='relative'>
                                <div className='size-5 group-hover:w-[72px] transition-all ease-linear duration-500 group-hover:h-6 top-1 -left-3 bg-primary rounded-full absolute z-10'></div>
                                <h3 className='text-xl font-medium z-50 relative'>
                                    Step2
                                </h3>
                            </div>
                            <div className='flex items-center gap-2'>
                                <img src="/assets/images/svg/earn.svg" className='size-5' alt="" />
                                <p className='text-xl font-medium'>
                                    Earn Points
                                </p>
                            </div>
                        </div>
                        <p className='mt-2 text-center'>
                            When you create a club, you'll earn a percentage (1% to 10%) of the profits from thousands of future clubs formed after yours, providing ongoing income as the network expands.
                        </p>
                    </div>
                    <div className='w-full relative group'>
                        <div className='flex justify-center items-center gap-4'>
                            <div className='relative'>
                                <div className='size-5 group-hover:w-[72px] transition-all ease-linear duration-500 group-hover:h-6 top-1 -left-3 bg-primary rounded-full absolute z-10'></div>
                                <h3 className='text-xl font-medium z-50 relative'>
                                    Step3
                                </h3>
                            </div>
                            <div className='flex items-center gap-2'>
                                <img src="/assets/images/svg/reddem.svg" className='size-5' alt="" />
                                <p className='text-xl font-medium'>
                                    Redeem Your Rewards
                                </p>
                            </div>
                        </div>
                        <p className='mt-2 text-center'>
                            Earn points easily through our program, and once you’ve accumulated enough, you can straightforwardly redeem them for cash or use them for transactions within our network. Or you can use those rewards to purchase anything from our network.
                        </p>
                    </div>
                    <div className='w-full relative group'>
                        <div className='flex justify-center items-center gap-4'>
                            <div className='relative'>
                                <div className='size-5 group-hover:w-[72px] transition-all ease-linear duration-500 group-hover:h-6 top-1 -left-3 bg-primary rounded-full absolute z-10'></div>
                                <h3 className='text-xl text-center font-medium z-50 relative'>
                                    Step4
                                </h3>
                            </div>
                            <div className='flex items-center gap-2'>
                                <img src="/assets/images/svg/enjoy.svg" className='size-5' alt="" />
                                <p className='text-xl font-medium'>
                                    Enjoy VIP Privileges
                                </p>
                            </div>
                        </div>
                        <p className='mt-2 text-center'>
                            As a Magic Club member, you’ll receive exclusive perks like early access to sales, special members-only discounts, and priority customer service.
                        </p>
                    </div>
                </div>
                <h3 className='2xl:text-2xl xl:text-2xl md:text-2xl lg:text-2xl text-xl mt-16 text-center font-medium'>
                    How Do I Get Magic Club Points?
                </h3>
                <div className="w-full p-8 bg-[#ffefd2] rounded-xl mt-8 2xl:mb-20 xl:mb-20 mb:mb-44 lg:mb-44 mb-32">
                    <video className='w-full' controls autoPlay={true} autoFocus>
                        <source src="https://res.cloudinary.com/dgnve8m5k/video/upload/v1725858013/assets/banners/magic-club-intro-damndeal.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </section>


            <Footer />
        </>
    )
}
