import { MdKeyboardArrowDown } from 'react-icons/md';
import VendorShopAddressForm from '../../components/common/ui/forms/vendor-shop-address-form';
import VendorPaymentInformationForm from '../../components/common/ui/forms/vendor-payment-information-form';
import { useForm, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCreateBusinessMutation } from '../../features/slices/vendor-api';
import { useSelector } from 'react-redux';
import { z } from 'zod';
import { useEffect } from 'react';
import { businessCategories } from '../../utils/categories';
import { AppState } from '../../redux/store';
import FileInput from '../../components/shared/files/FileInput';
import { useToast } from '../../components/shared/ToastContext';
import SocialProfileLinks from '../../components/common/ui/forms/vendor-social-media-form';
import { faC } from '@fortawesome/free-solid-svg-icons';
import { businessDefaultValues, businessSchema } from '../../features/schemas/business-schema';


export default function CreateNewShopPage() {
    const { user } = useSelector((state: AppState) => state.auth);
    const [create, { data, isLoading, error }] = useCreateBusinessMutation();
    const { showToast } = useToast();
    const methods = useForm({
        resolver: zodResolver(businessSchema),
        defaultValues: businessDefaultValues,
    });

    const { handleSubmit, reset, watch, setValue, formState: { errors } } = methods;

    const selectedCategory = watch('category');

    const onSubmit = (values: any) => {
        let data = {
            ...values,
            address2: values.address2 || ' ',
            vendorId: user?._id,
        }
        create(data);
    };

    useEffect(() => {
        if (!isLoading && data) {
            showToast('Business registered successfully', "success", "Business registered successfully");
            reset();
        } else if (!isLoading && error) {
            showToast('An error occurred while registering business', "error", "An error occurred while registering business");
        }
    }, [isLoading, data, error, reset]);

    const handleFileChange = (field:
        | "profile"
        | "backgroundImage"
    ) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file as Blob);

            reader.onload = () => {
                const base64File = reader.result as string;
                setValue(field, base64File);
            };
        }
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='w-full p-8 pt-5 bg-white rounded-3xl'>
                    <h2 className='text-black text-[22px] font-medium leading-snug'>Create New Business</h2>
                    <h6 className="text-black text-base mt-4 font-normal leading-snug">Business profile</h6>

                    <div className='flex 2xl:flex-row xl:flex-row md:flex-row lg:flex-row flex-col gap-8 pt-5'>
                        <img className="w-28 h-28 rounded-lg border border-black/opacity-10" alt='Cart' src={watch('profile') || "https://via.placeholder.com/112x112"} />
                        <FileInput
                            id="profile"
                            accept="image/*"
                            onChange={handleFileChange('profile')}
                        />
                        {errors.profile && <p role="alert" className="text-sm text-red-500 mt-2">{errors.profile.message}</p>}
                    </div>

                    <h6 className="text-black text-base mt-4 font-normal leading-snug">Business Cover Image</h6>

                    <div className='flex 2xl:flex-row xl:flex-row md:flex-row lg:flex-row flex-col gap-8 pt-5'>
                        <img className="w-28 h-28 rounded-lg border border-black/opacity-10" alt='Cart' src={watch('backgroundImage') || "https://via.placeholder.com/112x112"} />
                        <FileInput
                            id="backgroundImage"
                            accept="image/*"
                            onChange={handleFileChange('backgroundImage')}
                        />
                        {errors.backgroundImage && <p role="alert" className="text-sm text-red-500 mt-2">{errors.backgroundImage.message}</p>}
                    </div>
                </div>

                <div className='w-full p-8 pt-5 mt-12 bg-white rounded-3xl'>
                    <h2 className='text-black text-[22px] font-medium leading-snug'>Basic Information</h2>

                    <div className="grid 2xl:grid-cols-3 xl:grid-cols-3 md:grid-cols-2 lg:grid-cols-2 grid-cols-1 gap-8 pt-8">
                    <div className="w-full relative">
                                <label htmlFor="businessName" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.businessName ? "text-red-500" : "text-zinc-800"}`}>Business Name *</label>
                                <input type="text" id='businessName' placeholder='Furniture Shop' {...methods.register("businessName")} className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.businessName ? "border-red-500" : "border-gray-200"}`} />
                                {errors.businessName && <p role="alert" className="text-sm text-red-500 mt-2">{errors.businessName.message}</p>}
                            </div>
                            <div className="w-full relative">
                                <label htmlFor="category" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.category ? "text-red-500" : "text-zinc-800"}`}>Business Category *</label>
                                <select
                                    {...methods.register("category")}
                                    className={`w-full rounded p-2 border border-gray-300 h-14 ${errors.category ? "border-red-500" : "border-gray-200"}`}
                                >
                                    <option value="" label="Select category" />
                                    {businessCategories.map(category => (
                                        <option key={category.value} value={category.value} label={category.label} />
                                    ))}
                                </select>
                                {errors.category && <p role="alert" className="text-sm text-red-500 mt-2">{errors.category.message}</p>}
                            </div>
                            <div className="w-full relative">

                                <label htmlFor="subCategory" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.subCategory ? "text-red-500" : "text-zinc-800"}`}>Sub Category *</label>

                                <select
                                    {...methods.register("subCategory")}
                                    className={`w-full rounded p-2 border border-gray-300 h-14 ${errors.subCategory ? "border-red-500" : "border-gray-200"}`}
                                >
                                    <option value="" label="Select sub category" />
                                    {selectedCategory && businessCategories.find(cat => cat.value === selectedCategory)?.subCategories.map(subCat => (
                                        <option key={subCat.value} value={subCat.value} label={subCat.label} />
                                    ))}
                                </select>
                                {errors.subCategory && <p role="alert" className="text-sm text-red-500 mt-2">{errors.subCategory.message}</p>}
                            </div>
                        <div className="w-full relative 2xl:col-span-2 xl:col-span-2 md:col-span-2 lg:col-span-2 col-span-1">
                            <label htmlFor="email" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.email ? "text-red-500" : "text-zinc-800"}`}>Email Address *</label>
                            <input type="email" placeholder='FurnitureShop@example.com' {...methods.register("email")} className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.email ? "border-red-500" : "border-gray-200"}`} />
                            {errors.email && <p role="alert" className="text-sm text-red-500 mt-2">{errors.email.message}</p>}
                        </div>
                        <div className="w-full relative">
                            <label htmlFor="phone" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.phone ? "text-red-500" : "text-zinc-800"}`}>Contact Number *</label>
                            <input type="text" placeholder='+91' {...methods.register("phone")} className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.phone ? "border-red-500" : "border-gray-200"}`} />
                            {errors.phone && <p role="alert" className="text-sm text-red-500 mt-2">{errors.phone.message}</p>}
                        </div>
                        <div className="w-full relative 2xl:col-span-3 xl:col-span-3 md:col-span-3 lg:col-span-3 col-span-1">
                            <label htmlFor="state" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.description ? "text-red-500" : "text-zinc-800"}`}>About Description *</label>
                            <textarea rows={5} cols={20} placeholder="About Description Write Here" {...methods.register("description")} className={`w-full border resize-none border-solid rounded-md pt-5 outline-none px-4 ${errors.description ? "border-red-500" : "border-gray-200"}`} />
                            {errors.description && <p role="alert" className="text-sm text-red-500 mt-2">{errors.description.message}</p>}
                        </div>
                    </div>
                </div>

                <VendorShopAddressForm />
                <VendorPaymentInformationForm />
                <SocialProfileLinks />
                <div className='w-full p-8 pt-5 mt-12 bg-white rounded-3xl'>
                    <h2 className='text-black text-[22px] font-medium leading-snug'>E-mail Notifications</h2>

                    <div className="grid 2xl:grid-cols-2 xl:grid-cols-2 grid-cols-1 gap-8 pt-8">
                        <div className="w-full relative col-span-2">
                            <div className="mb-4 text-neutral-500 text-sm font-normal leading-snug">Set your email notification for messaging feature.</div>
                            <textarea rows={4} placeholder='Write message here..........' className="w-full resize-none p-3 border border-solid border-gray-200 rounded-md outline-none px-4" />
                        </div>
                    </div>

                    <div className="w-full pt-12 h-[39px] justify-end items-center gap-[15px] inline-flex">
                        <div className="p-[7px] bg-gray-200 rounded-[100px] justify-start items-start gap-[15px] flex">
                            <div className="w-[25px] h-[25px] bg-neutral-500 rounded-full"></div>
                            <div className="w-[25px] h-[25px] bg-gray-200 rounded-full"></div>
                        </div>
                        <div className="w-[174px] text-right text-black text-xl font-medium leading-snug">Enable Notification</div>
                    </div>
                </div>

                <div className="flex justify-end gap-8 pt-8">
                    <button disabled={isLoading} type="submit" className="w-fit h-[46px] text-center text-white text-xl font-medium leading-snug px-10 py-3 bg-zinc-800 rounded justify-center items-center gap-2.5 inline-flex">
                        {
                            isLoading ? "Loading..." : "Publish"
                        }
                    </button>
                    {/* <button type="button" className="w-fit h-[46px] px-10 py-3 bg-transparent text-center text-zinc-800 text-xl font-medium leading-snug rounded border border-zinc-800 justify-center items-center gap-2.5 inline-flex">
                        Canc
                    </button> */}
                </div>
            </form>
        </FormProvider>
    );
}
