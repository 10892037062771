import { useState } from 'react'
import Accordion from '../../components/common/ui/accordion'
const accordion = [
    {
        id: 1,
        header: 'Q: What is Damndeal?',
        content: "Damndeal is an online marketplace where you can buy and sell a wide range of products, from electronics and fashion to home goods and more. We connect buyers and sellers, offering a platform for convenient and secure transactions."
    }, {
        id: 2,
        header: 'Q: How do I create an account?',
        content: "To create an account, click on the Sign Up button on our homepage. You can sign up using your email address or social media accounts. Follow the prompts to set up your profile and start exploring Damndeal."
    },
    {
        id: 3,
        header: "Q: Is Damndeal available in my country?",
        content: "Damndeal serves customers worldwide. However, the availability of certain products and shipping options may vary depending on your location."
    },
    {
        id: 4,
        header: "Q: How do I make a purchase?",
        content: " To purchase an item, browse the marketplace, select the product you're interested in, and click Buy Now or Add to Cart. Follow the checkout process to complete your order."
    },
    {
        id: 5,
        header: "Q: What payment methods are accepted?",
        content: " We accept various payment methods, including credit/debit cards, PayPal, and Damndeal gift cards. The available options may vary depending on your location."
    },
    {
        id: 6,
        header: "Q: Can I track my order?",
        content: "Yes, once your order is shipped, you'll receive a tracking number. You can use this number to track your order's status through our website or the carrier's tracking service."
    },
    {
        id: 7,
        header: "Q: What is your return policy?",
        content: "Our return policy allows you to return most items within 30 days of receipt. The item must be in its original condition. Some items, such as perishables and personal care products, may not be eligible for return. For details, please visit our Return Policy page"
    },
    {
        id: 8,
        header: "Q: How do I sell on Damndeal?",
        content: " To start selling, create a seller account by clicking on the Sell button. List your products by providing detailed descriptions, high-quality images, and pricing. Once approved, your products will be available for buyers to purchase."
    },
    {
        id: 9,
        header: "Q: Are there fees for selling?",
        content: " Damndeal charges a commission on each sale and a small listing fee. The exact rates can be found on our Seller Fees page."
    },
    {
        id: 10,
        header: "Q: How do I get paid?",
        content: " Payments are processed and deposited into your account after the order is confirmed delivered and the return window has passed. You can set up your preferred payment method in your seller account settings."
    },

    {
        id: 11,
        header: "Q: What should I do if I have a dispute with a buyer?",
        content: "We encourage sellers to communicate directly with buyers to resolve issues. If a resolution cannot be reached, Damndeal offers a dispute resolution service. Please refer to our Dispute Resolution page for more information."
    },

    {
        id: 12,
        header: "Q: How do I reset my password?",
        content: "If you've forgotten your password, click on the Forgot Password link on the login page. Enter your registered email address, and we'll send you instructions to reset your password."
    },


    {
        id: 13,
        header: "Q: How does Damndeal protect my information?",
        content: "We use industry-standard security measures to protect your personal and payment information. For more details, please review our Privacy Policy."
    },

    {
        id: 14,
        header: "Q: Can I close my account?",
        content: "Yes, you can close your account at any time by contacting our customer service team. Please note that you will need to resolve any outstanding transactions before closing your account."
    },

    {
        id: 15,
        header: "Q: How can I contact Damndeal support?",
        content: "You can reach our customer support team via the Contact Us page on our website, email, or live chat. Our team is available 24/7 to assist you."
    },
    {
        id: 16,
        header: "Q: Where can I find more information about Damndeal policies?",
        content: "For detailed information on our policies, including shipping, returns, and privacy, please visit our Policies page."
    },

]
export default function NeedHelpPage() {
    const [searchItem, setSearchItem] = useState('')
    const [filterAccordion, setFilterAccordion] = useState(accordion)

    const handleInputChange = (e: any) => {
        const searchTerm = e.target.value;
        setSearchItem(searchTerm)

        const filteredItems = accordion.filter((accordion) =>
            accordion.header.toLowerCase().includes(searchTerm.toLowerCase())
        );

        setFilterAccordion(filteredItems);
    }
    return (
        <>
            <div className='w-full bg-white p-8 lg:rounded-3xl h-fit'>
                <h2 className="text-black text-center text-[22px] font-medium leading-snug">How We Can Help You?</h2>
                <div className='w-full flex justify-center relative pt-6'>
                    <div className="2xl:w-[701px] xl:w-[701px] relative w-full h-[62px] pl-[15px] pr-2.5 py-2.5 bg-white rounded-md shadow border justify-start items-center inline-flex">
                        <div className="justify-start items-start gap-[15px] flex w-full">
                            <div className="size-6 relative top-1.5 left-1">
                                <img loading="lazy" src="/assets/images/svg/search.svg" alt="Search" />
                            </div>
                            <div className="justify-center items-center flex w-full">
                                <input type='text' value={searchItem}
                                    onChange={handleInputChange} className="text-neutral-500 w-full outline-none border-none text-base font-medium" placeholder='Ask a question' />
                            </div>
                        </div>
                        <button type='button' className="px-[30px] absolute border-none outline-none right-4 py-2.5 text-center text-zinc-800 text-base font-medium leading-snug bg-yellow-300 rounded justify-center items-center gap-2.5 2xl:flex xl:flex md:flex lg:flex hidden">
                            Search
                        </button>
                    </div>
                </div>
                <p className="text-center text-neutral-500 text-base font-normal mt-8 leading-snug">Choose category to quickly find the help you need</p>
                <div className='flex justify-center'>
                    <div className='grid 2xl:grid-cols-3 gap-10 xl:grid-cols-3 grid-cols-1 pt-8'>
                        <div className="w-full h-[129px] px-[70px] py-[30px] bg-neutral-100/95 rounded-[10px] border border-black/opacity-10 flex-col justify-start items-center gap-[15px] inline-flex">
                            <img loading="lazy" className="w-[35px] h-[35px]" src="/assets/images/svg/flag.svg" alt='Flag' />
                            <div className="text-neutral-500 text-base font-normal">Getting Started</div>
                        </div>
                        <div className="w-full h-[129px] px-[70px] py-[30px] bg-neutral-100/95 rounded-[10px] border border-black/opacity-10 flex-col justify-start items-center gap-[15px] inline-flex">
                            <img loading="lazy" className="w-[35px] h-[35px]" src="/assets/images/svg/pricing.svg" alt='Pricing' />
                            <div className="text-neutral-500 text-base font-normal">Pricing & Plans</div>
                        </div>
                        <div className="w-full h-[129px] px-[70px] py-[30px] bg-neutral-100/95 rounded-[10px] border border-black/opacity-10 flex-col justify-start items-center gap-[15px] inline-flex">
                            <img loading="lazy" className="w-[35px] h-[35px]" src="/assets/images/svg/orders.svg" alt='Orders' />
                            <div className="text-neutral-500 text-base font-normal">For Orders</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='w-full bg-white p-8 lg:rounded-3xl h-auto mt-8'>
                <h2 className="text-black text-left text-[22px] font-medium leading-snug">General Question</h2>
                {filterAccordion.length === 0 ? (
                    <p className="text-center text-neutral-500 text-base font-normal mt-8">😢 No matching results found!!!</p>
                ) : (
                    <>
                        {filterAccordion.map((accordion => (
                            <div key={accordion.id}>
                                <Accordion header={accordion.header}>
                                    <p>
                                        {accordion.content}
                                    </p>
                                </Accordion>
                            </div>
                        )))}
                    </>
                )}

            </div>

        </>
    )
}
