import React, { useState } from 'react'
import { HiOutlineMail } from "react-icons/hi";
import { useToast } from '../../../shared/ToastContext';

export default function SubscribeBanenr() {
  const { showToast } = useToast()

  const [email, setEmail] = useState("");
  const handleSubscribe = () => {
    if (!email) {
      return showToast("Email is required", "error", "Email is required")
    }
    if (!email.includes("@")) {
      return showToast("Invalid email address", "error", "Invalid email address")
    }
    setEmail("");
    showToast("Subscribed successfully", "success", "Subscribed successfully")
  }

  return (
    <div className='bg-center bg-cover bg-no-repeat relative w-full pt-5'>
      <img src="/assets/images/banner/subscribe.png" className='2xl:h-auto xl:h-auto md:h-auto lg:h-auto h-44 w-full' alt="" />

      {/* ======= Text here ======= */}
      <div className='absolute 2xl:top-20 xl:top-20 md:top-20 lg:top-20 top-14 left-0 right-0'>
        <div className='max-w-screen-lg mx-auto 2xl:px-0 xl:px-0 px-4'>
          <div className='flex 2xl:flex-row xl:flex-row md:flex-row lg:flex-row flex-col gap-5 justify-between items-center'>
            <div className='2xl:w-1/2 xl:w-1/2 md:w-1/2 lg:w-1/2 w-full'>
              <h1 className='text-white 2xl:text-left xl:text-left md:text-left lg:text-left text-center text-xl font-medium'>Subscribe to see <span className='text-black'>Amazing Deals</span> every day</h1>
            </div>
            <form className='2xl:w-1/2 xl:w-1/2 md:w-1/2 lg:w-1/2 w-full' onSubmit={(e) => {
              e.preventDefault();
              handleSubscribe();
            }}>
              <div className='w-full bg-white px-10 py-2.5 rounded-full relative'>
                <HiOutlineMail className='absolute top-4 left-4' />
                <input type='email' required = {true} placeholder='Enter your email address' className='w-full border-none outline-none bg-transparent h-6 text-gray-700 text-sm font-normal' value={email} onChange={(e) => setEmail(e.target.value)} />
                <button type="submit" className='w-fit 2xl:px-8 xl:px-8 md:px-8 lg:px-8 px-4 absolute top-1.5 right-3 py-2 bg-black rounded-full text-xs text-white'>Subscribe</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
