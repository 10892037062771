import React from 'react'
import { useNavigate } from 'react-router-dom'
import useGoogleAnalytics from '../../../../hooks/google-analytics'

type MainBannerProps = {
    title?: string,
    bannerImage?: string,
    description?: string,
    link?: string,
    showContent?: boolean
}
const MainBanner = ({
    title,
    bannerImage,
    description,
    link,
    showContent = false
}: MainBannerProps) => {
    const { trackEvent } = useGoogleAnalytics()
    {/** full image with proper lazy loading and fall back and 
    if url is given then on click it should go to that page */}
    const navigate = useNavigate()
    const handleNavigate = () => {
        trackEvent('banner_click', { event_label: title })
        if (link) navigate(link)
    }
    return (
        <div className='w-full relative cursor-pointer' onClick={handleNavigate}>
            <img src={bannerImage} alt={title || description?.substring(0, 20)} className='w-full h-full object-cover min-h-[80px] min-w-full bg-gray-100 md:rounded-xl' loading='lazy' />
            {showContent && <div className='absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center'>
                <div className='text-white text-center'>
                    <h1 className='text-2xl'>{title}</h1>
                    <p>{description}</p>
                </div>
            </div>}
        </div>
    )
}

export default MainBanner