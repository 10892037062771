import React, { useState, useEffect, useRef } from "react";
import "./lottery.css";
import { useGetPoolEntriesQuery, useSelectWinnerMutation } from "../../../features/api/pool-api";
import { useParams } from "react-router-dom";

interface LotteryDomProps { }

const LotteryDom: React.FC<LotteryDomProps> = () => {
    const { id } = useParams();
    const [selectWinner] = useSelectWinnerMutation();
    const { data } = useGetPoolEntriesQuery(id, {
        skip: !id,
    });
    const [list] = useState([
        "$100",
        "$500",
        "$9,999",
        "$1",
        "$60",
        "$1,000",
        "$4.44",
        "$0",
        "$333"
    ]);
    const [radius] = useState(75); // PIXELS
    const [rotate, setRotate] = useState(0); // DEGREES
    const [easeOut, setEaseOut] = useState(0); // SECONDS
    const [angle, setAngle] = useState(0); // RADIANS
    const [top, setTop] = useState<number | null>(null); // INDEX
    const [offset, setOffset] = useState<number | null>(null); // RADIANS
    const [net, setNet] = useState<number | null>(null); // RADIANS
    const [result, setResult] = useState<number | null>(null); // INDEX
    const [spinning, setSpinning] = useState(false);
    const [winner, setWinner] = useState(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        renderWheel();
    }, []);

    const renderWheel = () => {
        const numOptions = list.length;
        const arcSize = (2 * Math.PI) / numOptions;
        setAngle(arcSize);

        topPosition(numOptions, arcSize);

        let angle = 0;
        for (let i = 0; i < numOptions; i++) {
            const text = list[i];
            renderSector(i + 1, text, angle, arcSize, getColor());
            angle += arcSize;
        }
    };

    const topPosition = (num: number, angle: number) => {
        let topSpot = null;
        let degreesOff = null;

        if (num === 9) {
            topSpot = 7;
            degreesOff = Math.PI / 2 - angle * 2;
        } else if (num === 8) {
            topSpot = 6;
            degreesOff = 0;
        } else if (num <= 7 && num > 4) {
            topSpot = num - 1;
            degreesOff = Math.PI / 2 - angle;
        } else if (num === 4) {
            topSpot = num - 1;
            degreesOff = 0;
        } else if (num <= 3) {
            topSpot = num;
            degreesOff = Math.PI / 2;
        }

        setTop(topSpot! - 1);
        setOffset(degreesOff!);
    };

    const renderSector = (
        index: number,
        text: string,
        start: number,
        arc: number,
        color: string
    ) => {
        const canvas = canvasRef.current;
        if (!canvas) return;
        const ctx = canvas.getContext("2d");
        if (!ctx) return;

        const x = canvas.width / 2;
        const y = canvas.height / 2;
        const radius = 75;
        const startAngle = start;
        const endAngle = start + arc;
        const angle = index * arc;
        const baseSize = radius * 3.33;
        const textRadius = baseSize - 150;

        ctx.beginPath();
        ctx.arc(x, y, radius, startAngle, endAngle, false);
        ctx.lineWidth = radius * 2;
        ctx.strokeStyle = color;

        ctx.font = "17px Arial";
        ctx.fillStyle = "black";
        ctx.stroke();

        ctx.save();
        ctx.translate(
            baseSize + Math.cos(angle - arc / 2) * textRadius,
            baseSize + Math.sin(angle - arc / 2) * textRadius
        );
        ctx.rotate(angle - arc / 2 + Math.PI / 2);
        ctx.fillText(text, -ctx.measureText(text).width / 2, 0);
        ctx.restore();
    };

    const getColor = () => {
        const r = Math.floor(Math.random() * 255);
        const g = Math.floor(Math.random() * 255);
        const b = Math.floor(Math.random() * 255);
        return `rgba(${r},${g},${b},0.4)`;
    };

    const spin = () => {
        const randomSpin = Math.floor(Math.random() * 900) + 500;
        setRotate(randomSpin);
        setEaseOut(2);
        setSpinning(true);


        setTimeout(() => {
            getResult(randomSpin);
        }, 2000);
    };

    const getResult = (spin: number) => {
        const netRotation = ((spin % 360) * Math.PI) / 180; // RADIANS
        let travel = netRotation + (offset || 0);
        let count = (top || 0) + 1;

        selectWinner(id).unwrap()
        .then((data) => {
            setWinner(data);
            console.log("Winner selected");
        }).catch(() => {
            console.log("Error selecting winner");
        });

        while (travel > 0) {
            travel = travel - angle;
            count--;
        }
        let resultIndex;
        if (count >= 0) {
            resultIndex = count;
        } else {
            resultIndex = list.length + count;
        }

        setNet(netRotation);
        setResult(resultIndex);
    };

    const reset = () => {
        setRotate(0);
        setEaseOut(0);
        setResult(null);
        setSpinning(false);
    };

    return (
        <div className="App body">
            <div className="">
                <h1>Spinning Prize Wheel React</h1>
                <span id="selector">&#9660;</span>
                <canvas
                    id="wheel"
                    ref={canvasRef}
                    width="500"
                    height="500"
                    style={{
                        transform: `rotate(${rotate}deg)`,
                        transition: `transform ${easeOut}s ease-out`
                    }}
                />

                {spinning ? (
                    <button className="button" type="button" id="reset" onClick={reset}>
                        reset
                    </button>
                ) : (
                    <button className="button" type="button" id="spin" onClick={spin}>
                        spin
                    </button>
                )}
                <div className="display">
                    <span id="readout">
                       {winner !== null ? `Winner: ${JSON.stringify(winner)}` : ""}
                        <span id="result">{result !== null ? list[result] : ""}</span>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default LotteryDom;
