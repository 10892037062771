import { useState } from 'react'
import Table from '../../components/common/ui/table'
import { useSelector } from 'react-redux';
import { useGetMagicClubsQuery } from '../../features/slices/api-slices';
import { useGetWalletQuery } from '../../features/api/products-api';
import { useGetEClubsQuery } from '../../features/api/ebook-api';

export default function MusicClubPage({ userId }: any) {
    const [activeTab, setActiveTab] = useState(0);
    const user = useSelector((state: any) => state?.auth.user);
    const { isLoading, data } = useGetWalletQuery();

    const tabs = [
        { id: 0, name: "E-coupon Book Clubs", content: <EBookClubsTab /> },
        { id: 1, name: "Magic Club", content: <MagicClubsTab /> },
    ]
    return (
        <div className='w-full bg-white 2xl:p-8 xl:p-8 md:p-8 lg:p-8 p-4 pt-0 lg:rounded-3xl'>
            {activeTab === 0 ? (
                <h3 className="text-black text-[22px] font-medium leading-snug">E-coupon Book Clubs</h3>
            ) : (
                <>
                    <h3 className="text-black text-[22px] font-medium leading-snug">Magic Club</h3>
                </>
            )
            }
            <div className="w-full mt-8 justify-start items-start gap-[25px] inline-flex flex-wrap">
                {tabs.map((tab, i) => (
                    <button key={tab.id} type='button' className={`px-5 py-2 rounded-full border border-black justify-center items-center flex ${activeTab === i ? 'bg-black text-white' : ''}`} onClick={() => setActiveTab(i)}>
                        <span className={`text-${activeTab === i ? 'white' : 'black'} text-sm font-normal`}>{tab.name}</span>
                    </button>
                ))}

            </div>

            <div className='flex 2xl:flex-row xl:flex-row md:flex-row lg:flex-row flex-col justify-between 2xl:items-center xl:items-center md:items-center lg:items-center items-start gap-6 pt-8'>
                <h4 className="text-zinc-800 text-xl font-medium leading-snug">User ID: {user?.referenceId}</h4>
                <button type='button' className="w-fit h-fit px-[15px] py-2.5 rounded-[7px] border border-black/opacity-10 justify-start items-start gap-[15px] inline-flex">
                    <div className="justify-center items-center flex">
                        <div className="text-black text-lg font-medium">Wallet Balance:</div>
                    </div>
                    <div className="justify-center items-center flex">
                        <div className="text-black text-lg font-medium">{!isLoading && data?.data?.totalAmount
                            ? "$" + data?.data?.totalAmount?.toFixed(2)
                            : "$0"}</div>
                    </div>
                </button>
            </div>

            {tabs[activeTab].content}
        </div>
    )
}


export const MagicClubsTab = () => {
    const { isLoading, data } = useGetMagicClubsQuery();

    return (
        <div>
            {
                isLoading ?
                    <div className="text-black text-lg ease-in-out animate-pulse">Loading...</div>
                    :
                    data?.data?.length > 0 ? <Table data={data?.data} /> : <div className="w-full flex justify-center items-center text-red-500 text-lg my-10 p-5">You have to make a purchase to start creating clubs</div>
            }
        </div>
    )
}

export const EBookClubsTab = () => {
    const { isLoading, data } = useGetEClubsQuery();
    console.log(data);

    return (
        <div>
            {
                isLoading ?
                    <div className="text-black text-lg ease-in-out animate-pulse">Loading...</div>
                    :
                    data?.data?.length > 0 ? <Table data={data?.data} /> : <div className="w-full flex justify-center items-center text-red-500 text-lg my-10 p-5">You have to make a purchase to start creating clubs</div>
            }
        </div>
    )
}