import React from 'react'

export default function SekeltonLoader() {
    return (
        <>
            <div className="w-full max-w-screen-2xl min-h-screen mx-auto pt-6 pb-8">
                <div className="animate-pulse">
                    <div className='pt-8 grid 2xl:grid-cols-4 xl:grid-cols-4 mb-5 grid-cols-2 gap-4'>
                        <div className='w-full h-5 bg-gray-200 rounded-full mb-4'></div>
                        <div className='w-full h-5 bg-gray-200 rounded-full mb-4'></div>
                        <div className='w-full h-5 bg-gray-200 rounded-full mb-4'></div>
                        <div className='w-full h-5 bg-gray-200 rounded-full mb-4'></div>
                    </div>
                    <div className='w-full relative'>
                        <div className="h-52 bg-gray-200 rounded mb-4"></div>
                        <div className='size-36 bg-gray-200 rounded-full absolute -bottom-20 left-10'></div>
                    </div>
                    <div className='grid 2xl:grid-cols-4 xl:grid-cols-4 grid-cols-1 gap-5 mt-36'>
                        <div className='w-full h-32 bg-gray-200 rounded mb-4'></div>
                        <div className='w-full h-32 bg-gray-200 rounded mb-4'></div>
                        <div className='w-full h-32 bg-gray-200 rounded mb-4'></div>
                        <div className='w-full h-32 bg-gray-200 rounded mb-4'></div>
                    </div>
                    <div className='pt-8'>
                        <div className='w-36 h-5 bg-gray-200 rounded-full mb-4'></div>
                    </div>
                    <div className='grid 2xl:grid-cols-2 xl:grid-cols-2 grid-cols-1 gap-5 mt-10'>
                        <div className='w-full h-64 bg-gray-200 rounded-lg mb-4'></div>
                        <div className='w-full h-64 bg-gray-200 rounded-lg mb-4'></div>
                    </div>
                    <div className='pt-8'>
                        <div className='w-64 h-5 bg-gray-200 rounded-full mb-4'></div>
                    </div>
                </div>
            </div>
        </>
    )
}
