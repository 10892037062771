import React from 'react'
import { BsCart2 } from 'react-icons/bs'
import { FaRegHeart } from 'react-icons/fa6'
import { LiaUserSolid } from 'react-icons/lia'
import { LuRefreshCw } from 'react-icons/lu'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { AppState } from '../../../../redux/store'


export const RefreshButton = ({
    onClick
}: {
    onClick: () => void
}) => {
    return (
        <button type="button" className='size-10 flex justify-center items-center bg-gray-100 rounded-full text-sm' onClick={onClick}>
            <LuRefreshCw className='text-lg' />
        </button>

    )
}

export const WishlistButton = () => {
    return (
        <button type="button" className='size-10 flex justify-center items-center bg-gray-100 rounded-full text-sm'>
            <FaRegHeart className='text-lg' />
        </button>

    )
}

export const LoginRegisterButton = () => {
    return (
        <>
            <div className='flex items-center gap-2'>
                <button type="button" className='size-10 flex justify-center items-center bg-gray-100 rounded-full text-sm'>
                    <LiaUserSolid className='text-xl' />
                </button>
                <div className='flex flex-col'>
                    <h6 className='mb-0 font-medium uppercase text-sm'>
                        Welcome
                    </h6>
                    <Link to={`/auth/signin`} className='text-xs uppercase font-medium cursor-pointer hover:text-primary'>LogIn/ Register</Link>
                </div>
            </div>
        </>
    )
}

export const CartButton = () => {
    const cartLength = useSelector((state: AppState) => state.product.cartLength)
    return (
        <>
            <div className='flex items-center gap-2 cursor-pointer relative'>
                {cartLength > 0 && (
                    <div className='absolute -bottom-1 flex justify-center items-center text-xs text-white left-7 size-4 bg-primary rounded-full'>{cartLength}</div>
                )}
                
                <button type="button" className='size-10 flex justify-center items-center bg-gray-100 rounded-full text-sm'>
                    <BsCart2 className='text-xl' />
                </button>
                <div className='flex-col hidden lg:flex'>
                    <h6 className='mb-0 font-medium uppercase text-sm'>
                        Cart
                    </h6>
                    {/* <span className='text-xs font-medium'>$5,670</span> */}
                </div>
            </div>
        </>
    )
}
