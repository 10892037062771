import { useState } from 'react';
import { Link } from 'react-router-dom'
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { SubMenuItemInterface } from '../../../interfaces/interface';
import { IoCloseSharp } from 'react-icons/io5';

const Sidebar = ({ show, setter, setShowSidebar }: any) => {
    const className = "bg-white w-[290px] transition-[margin-left] z-[200] ease-in-out duration-500 fixed 2xl:static xl:static top-0 bottom-0 left-0";
    // Append class based on state of sidebar visiblity
    const appendClass = show ? " ml-0" : " ml-[-340px] 2xl:ml-0 xl:ml-0";
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    // Overlay to prevent clicks in background, also serves as our close button
    const ModalOverlay = () => (
        <div
            className={`flex 2xl:hidden xl:hidden fixed top-0 right-0 bottom-0 left-0 bg-black/50 z-30`}
            onClick={() => {
                setter((oldVal: any) => !oldVal);
            }}

        >

        </div>
    )

    const handleCloseMenu = () => {
        setShowSidebar(false);
    }

    return (
        <>
            <div className={`${className}${appendClass}`}>
                <button type='button' onClick={handleCloseMenu} className='absolute top-3 right-3 size-8 z-50 cursor-pointer flex justify-center items-center border-none outline-none'>
                    <IoCloseSharp color='#000' size={20} />
                </button>
                <div className="p-2 flex">
                </div>
                <div className="p-2 flex flex-col gap-1.5 items-center">
                    <Link to="/">
                        <img loading="lazy"
                            src={'/logo.gif'}
                            alt="logo"
                            className="w-full h-[100px] object-cover"
                        />
                    </Link>
                </div>
                <div className="flex flex-col 2xl:gap-0 xl:gap-0 gap-2 mt-6 2xl:pl-5 xl:pl-0 pl-6">
                    <Link onClick={handleCloseMenu}
                        to={`/user-panel/profile`}
                        className={`flex gap-1 [&>*]:my-auto 2xl:text-base xl:text-xs hover:border-b hover:border-solid hover:border-b-yellow-500 2xl:pl-6 xl:pl-4 2xl:py-4 xl:py-3.5 py-3 border-b-[1px] border-b-white/10`}
                    >
                        <div className="text-xl flex [&>*]:mx-auto w-[30px]">
                            <img loading="lazy" src='/assets/icons/side-bar/profile.svg' alt="" />
                        </div>
                        <div>Profile</div>
                    </Link>

                    <Link onClick={handleCloseMenu}
                        to={`/user-panel/change-password`}
                        className={`flex gap-1 [&>*]:my-auto 2xl:text-base xl:text-xs hover:border-b hover:border-solid hover:border-b-yellow-500 2xl:pl-6 xl:pl-4 2xl:py-4 xl:py-3.5 py-2 border-b-[1px] border-b-white/10`}
                    >
                        <div className="text-xl flex [&>*]:mx-auto w-[30px]">
                            <img loading="lazy" src='/assets/icons/side-bar/chnage-password.svg' alt="" />
                        </div>
                        <div>Change Password</div>
                    </Link>

                    <Link onClick={handleCloseMenu}
                        to={`/user-panel/my-orders`}
                        className={`flex gap-1 [&>*]:my-auto 2xl:text-base xl:text-xs hover:border-b hover:border-solid hover:border-b-yellow-500 2xl:pl-6 xl:pl-4 2xl:py-4 xl:py-3.5 py-2 border-b-[1px] border-b-white/10`}
                    >
                        <div className="text-xl flex [&>*]:mx-auto w-[30px]">
                            <img loading="lazy" src='/assets/icons/side-bar/cart.svg' alt="" />
                        </div>
                        <div>My Orders</div>
                    </Link>
                    <Link onClick={handleCloseMenu}
                        to={`/user-panel/magic-club`}
                        className={`flex gap-1 [&>*]:my-auto 2xl:text-base xl:text-xs hover:border-b hover:border-solid hover:border-b-yellow-500 2xl:pl-6 xl:pl-4 2xl:py-4 xl:py-3.5 py-2 border-b-[1px] border-b-white/10`}
                    >
                        <div className="text-xl flex [&>*]:mx-auto w-[30px]">
                            <img loading="lazy" src='/assets/icons/side-bar/magic.svg' alt="" />
                        </div>
                        <div>Magic Club</div>
                    </Link>
                    <Link onClick={handleCloseMenu}
                        to={`/user-panel/discount-and-directory`}
                        className={`flex gap-1 [&>*]:my-auto 2xl:text-base xl:text-xs hover:border-b hover:border-solid hover:border-b-yellow-500 2xl:pl-6 xl:pl-4 2xl:py-4 xl:py-3.5 py-2 border-b-[1px] border-b-white/10`}
                    >
                        <div className="text-xl flex [&>*]:mx-auto w-[30px]">
                            <img loading="lazy" src='/assets/icons/side-bar/discount.svg' alt="" />
                        </div>
                        <div>Discount & Directory</div>
                    </Link>
                    <Link onClick={handleCloseMenu}
                        to={`/user-panel/wishlist`}
                        className={`flex gap-1 [&>*]:my-auto 2xl:text-base xl:text-xs hover:border-b hover:border-solid hover:border-b-yellow-500 2xl:pl-6 xl:pl-4 2xl:py-4 xl:py-3.5 py-2 border-b-[1px] border-b-white/10`}
                    >
                        <div className="text-xl flex [&>*]:mx-auto w-[30px]">
                            <img loading="lazy" src='/assets/icons/side-bar/whitelist.svg' alt="" />
                        </div>
                        <div>Wishlist</div>
                    </Link>

                    <Link onClick={handleCloseMenu}
                        to={`/user-panel/my-cart`}
                        className={`flex gap-1 [&>*]:my-auto 2xl:text-base xl:text-xs hover:border-b hover:border-solid hover:border-b-yellow-500 2xl:pl-6 xl:pl-4 2xl:py-4 xl:py-3.5 py-2 border-b-[1px] border-b-white/10`}
                    >
                        <div className="text-xl flex [&>*]:mx-auto w-[30px]">
                            <img loading="lazy" src='/assets/icons/side-bar/cart-my.svg' alt="" />
                        </div>
                        <div>My Cart</div>
                    </Link>

                    <Link onClick={handleCloseMenu}
                        to={`/user-panel/my-question`}
                        className={`flex gap-1 [&>*]:my-auto 2xl:text-base xl:text-xs hover:border-b hover:border-solid hover:border-b-yellow-500 2xl:pl-6 xl:pl-4 2xl:py-4 xl:py-3.5 py-2 border-b-[1px] border-b-white/10`}
                    >
                        <div className="text-xl flex [&>*]:mx-auto w-[30px]">
                            <img loading="lazy" src='/assets/icons/side-bar/question-blub.svg' alt="" />
                        </div>
                        <div>My Questions</div>
                    </Link>
                    <Link onClick={handleCloseMenu}
                        to={`/user-panel/my-refunds`}
                        className={`flex gap-1 [&>*]:my-auto 2xl:text-base xl:text-xs hover:border-b hover:border-solid hover:border-b-yellow-500 2xl:pl-6 xl:pl-4 2xl:py-4 xl:py-3.5 py-2 border-b-[1px] border-b-white/10`}
                    >
                        <div className="text-xl flex [&>*]:mx-auto w-[30px]">
                            <img loading="lazy" src='/assets/icons/side-bar/refund.svg' alt="" />
                        </div>
                        <div>My Refunds</div>
                    </Link>
                    <Link onClick={handleCloseMenu}
                        to={`/user-panel/need-help`}
                        className={`flex gap-1 [&>*]:my-auto 2xl:text-base xl:text-xs hover:border-b hover:border-solid hover:border-b-yellow-500 2xl:pl-6 xl:pl-4 2xl:py-4 xl:py-3.5 py-2 border-b-[1px] border-b-white/10`}
                    >
                        <div className="text-xl flex [&>*]:mx-auto w-[30px]">
                            <img loading="lazy" src='/assets/icons/side-bar/need-help.svg' alt="" />
                        </div>
                        <div>Need Help</div>
                    </Link>
                    <button type='button'

                        onClick={openModal}
                        className={`flex gap-1 border-none outline-none bg-transparent [&>*]:my-auto 2xl:text-base xl:text-xs hover:border-b hover:border-solid hover:border-b-yellow-500 2xl:pl-6 xl:pl-4 2xl:py-4 xl:py-3.5 py-2 border-b-[1px] border-b-white/10`}
                    >
                        <div className="text-xl flex [&>*]:mx-auto w-[30px]">
                            <img loading="lazy" src='/assets/icons/side-bar/log-out.svg' alt="" />
                        </div>
                        <div>Logout</div>
                    </button>
                </div>
            </div>
            {show ? <ModalOverlay /> : <></>}
            {isModalOpen && <ModalLogoutOverlay isOpen={isModalOpen} onClose={closeModal} />}
        </>
    )
}


// ====== logout Model =========
const ModalLogoutOverlay = ({ isOpen, onClose }: any) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white rounded-lg shadow-lg w-full max-w-md p-6 relative">
                <button
                    onClick={onClose}
                    className="absolute top-2 right-4 text-xl text-gray-500 hover:text-gray-700"
                >
                    &times;
                </button>
                <h2 className="text-lg font-semibold mb-8 mt-4">Are you sure you want to logout?</h2>
                <div className="flex justify-end gap-4">
                    <button
                        onClick={() => {
                            localStorage.removeItem("token");
                            window.location.assign('/')
                        }}
                        className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
                    >
                        Logout
                    </button>
                    <button
                        onClick={onClose}
                        className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-200"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
}

const VendorSidebar = ({ show, setter, setShowSidebar }: any) => {
    const [showSubMenu, setshowSubMenu] = useState(false);
    const [showProjectManagement, setShowProjectManagement] = useState(false);
    const className = "bg-white w-[328px] transition-[margin-left] ease-in-out duration-500 fixed 2xl:static xl:static top-0 bottom-0 left-0 z-40";
    // Append class based on state of sidebar visiblity
    const appendClass = show ? " ml-0" : " ml-[-340px] 2xl:ml-0 xl:ml-0";

    // Overlay to prevent clicks in background, also serves as our close button
    const ModalOverlay = () => (
        <div
            className={`flex 2xl:hidden xl:hidden fixed top-0 right-0 bottom-0 left-0 bg-black/50 z-30`}
            onClick={() => {
                setter((oldVal: any) => !oldVal);
            }}
        />
    )


    const handleCloseMenu = () => {
        setShowSidebar(false);
    }

    return (
        <>
            <div className={`${className}${appendClass}`}>
                <div className="p-2 flex">
                    {/* <Link to="/">
                        <img loading="lazy" src="/assets/images/logo/logo.png" alt="" />
                    </Link> */}
                </div>
                <div className="p-2 flex flex-col gap-1.5 items-center">
                    <Link to="/">
                        {/*eslint-disable-next-line*/}
                        {/* <img loading="lazy" src={`${data?.avatar}`} className='size-32 rounded-full' alt="" /> */}
                        <img loading="lazy"
                            src={'/logo.gif'}
                            alt="logo"
                            className="w-full h-[100px] object-cover"
                        />
                    </Link>
                    {/* <h4 className='text-lg capitalize mt-2 font-medium'>
                        {data?.name}
                    </h4> */}
                    {/* <h6>
                        @Username
                    </h6> */}
                </div>
                <div className="flex flex-col mt-6 2xl:pl-5 xl:pl-0 pl-6 gap-7 2xl:gap-0 xl:gap-0">
                    <Link onClick={handleCloseMenu}
                        to={`/vendor-panel/profile`}
                        className={`flex gap-1 [&>*]:my-auto 2xl:text-base xl:text-xs hover:border-b hover:border-solid hover:border-b-yellow-500 2xl:pl-4 xl:pl-4 2xl:py-4 xl:py-3.5 border-b-[1px] border-b-white/10`}
                    >
                        <div className="text-xl flex [&>*]:mx-auto w-[30px]">
                            <img loading="lazy" src='/assets/icons/side-bar/profile.svg' alt="" />
                        </div>
                        <div>Profile</div>
                    </Link>
                    <ul className='flex flex-col 2xl:gap-0 xl:gap-0 md:gap-0 lg:gap-0 gap-8'>
                        <li>
                            <button type='button'
                                onClick={() => {
                                    setshowSubMenu((oldVal: any) => !oldVal);
                                }}
                                className={`flex gap-1 [&>*]:my-auto 2xl:text-base outline-none border-none bg-transparent xl:text-xs 2xl:pl-5 xl:pl-4 2xl:py-4 xl:py-3.5 border-b-[1px] border-b-white/10`}
                            >
                                <div className="text-xl flex [&]*:mx-auto w-[30px]">
                                    <img loading="lazy" src='/assets/icons/side-bar/shop.svg' alt="" />
                                </div>
                                <div className=''>My Businesses</div>
                                <div>{showSubMenu ? <IoIosArrowUp className='text-amber-300 text-xl font-bold' /> : <IoIosArrowDown className='text-xl' />}</div>
                            </button>
                        </li>
                        {showSubMenu && (
                            <ul className='flex flex-col border-l border-dashed border-neutral-300 2xl:ml-8 xl:ml-8'>

                                <Link onClick={handleCloseMenu} to={`/vendor-panel/business-management`}
                                    className={`flex gap-1 mb-2 [&>*]:my-auto ml-5 2xl:text-base xl:text-xs 2xl:py-2 xl:py-2 border-b-[1px] border-b-white/10`}
                                >
                                    <div className='text-sm text-neutral-500 hover:text-black'>
                                        Business Management
                                    </div>
                                </Link>

                                <Link onClick={handleCloseMenu} to={`/vendor-panel/add-new-business`}
                                    className={`flex gap-1 mb-2 [&>*]:my-auto ml-5 2xl:text-base xl:text-xs 2xl:py-2 xl:py-2 border-b-[1px] border-b-white/10`}
                                >
                                    <div className='text-sm text-neutral-500 hover:text-black'>
                                        Add New Business
                                    </div>
                                </Link>
                            </ul>
                        )}
                    </ul>
                    <ul className='flex flex-col 2xl:gap-0 xl:gap-0 md:gap-0 lg:gap-0 gap-8'>
                        <li>
                            <button type='button'
                                onClick={() => {
                                    setShowProjectManagement((oldVal: any) => !oldVal);
                                }}
                                className={`flex gap-1 [&>*]:my-auto 2xl:text-base outline-none border-none bg-transparent xl:text-xs 2xl:pl-5 xl:pl-4 2xl:py-4 xl:py-3.5 border-b-[1px] border-b-white/10`}
                            >
                                <div className="text-xl flex [&]*:mx-auto w-[30px]">
                                    <img loading="lazy" src='/assets/icons/side-bar/product-management.svg' alt="" />
                                </div>
                                <div className=''>Product Management</div>
                                <div>{showProjectManagement ? <IoIosArrowUp className='text-amber-300 text-xl font-bold' /> : <IoIosArrowDown className='text-xl' />}</div>
                            </button>
                        </li>
                        {showProjectManagement && (
                            <ul className='flex flex-col border-l border-dashed border-neutral-300 2xl:ml-8 xl:ml-8'>

                                <Link onClick={handleCloseMenu} to={`/vendor-panel/coming-soon`}
                                    className={`flex gap-1 mb-2 [&>*]:my-auto ml-5 2xl:text-base xl:text-xs 2xl:py-2 xl:py-2 border-b-[1px] border-b-white/10`}
                                >
                                    <div className='text-sm text-neutral-500 hover:text-black'>
                                        All Product
                                    </div>
                                </Link>

                                <Link onClick={handleCloseMenu} to={`/vendor-panel/coming-soon`}
                                    className={`flex gap-1 mb-2 [&>*]:my-auto ml-5 2xl:text-base xl:text-xs 2xl:py-2 xl:py-2 border-b-[1px] border-b-white/10`}
                                >
                                    <div className='text-sm text-neutral-500 hover:text-black'>
                                        Add Product
                                    </div>
                                </Link>

                                <Link onClick={handleCloseMenu} to={`/vendor-panel/coming-soon`}
                                    className={`flex gap-1 mb-2 [&>*]:my-auto ml-5 2xl:text-base xl:text-xs 2xl:py-2 xl:py-2 border-b-[1px] border-b-white/10`}
                                >
                                    <div className='text-sm text-neutral-500 hover:text-black'>
                                        My Draft
                                    </div>
                                </Link>
                                <Link onClick={handleCloseMenu} to={`/vendor-panel/coming-soon`}
                                    className={`flex gap-1 mb-2 [&>*]:my-auto ml-5 2xl:text-base xl:text-xs 2xl:py-2 xl:py-2 border-b-[1px] border-b-white/10`}
                                >
                                    <div className='text-sm text-neutral-500 hover:text-black'>
                                        All Low and Out of stock Products
                                    </div>
                                </Link>
                            </ul>
                        )}
                    </ul>
                    <Link onClick={handleCloseMenu}
                        to={`/vendor-panel/coupons`}
                        className={`flex gap-1 [&>*]:my-auto 2xl:text-base xl:text-xs hover:border-b hover:border-solid hover:border-b-yellow-500 2xl:pl-4 xl:pl-4 2xl:py-4 xl:py-3.5 border-b-[1px] border-b-white/10`}
                    >
                        <div className="text-xl flex [&>*]:mx-auto w-[30px]">
                            <img loading="lazy" src='/assets/icons/side-bar/magic.svg' alt="" />
                        </div>
                        <div>Coupons</div>
                    </Link>
                    <Link onClick={handleCloseMenu}
                        to={`/vendor-panel/inventory`}
                        className={`flex gap-1 [&>*]:my-auto 2xl:text-base xl:text-xs hover:border-b hover:border-solid hover:border-b-yellow-500 2xl:pl-4 xl:pl-4 2xl:py-4 xl:py-3.5 border-b-[1px] border-b-white/10`}
                    >
                        <div className="text-xl flex [&>*]:mx-auto w-[30px]">
                            <img loading="lazy" src='/assets/icons/side-bar/dollar.svg' alt="" />
                        </div>
                        <div>Inventory</div>
                    </Link>

                    <Link onClick={handleCloseMenu}
                        to={`/vendor-panel/orders`}
                        className={`flex gap-1 [&>*]:my-auto 2xl:text-base xl:text-xs hover:border-b hover:border-solid hover:border-b-yellow-500 2xl:pl-4 xl:pl-4 2xl:py-4 xl:py-3.5 border-b-[1px] border-b-white/10`}
                    >
                        <div className="text-xl flex [&>*]:mx-auto w-[30px]">
                            <img loading="lazy" src='/assets/icons/side-bar/orders.svg' alt="" />
                        </div>
                        <div>Orders</div>
                    </Link>

                    <Link onClick={handleCloseMenu}
                        to={`/vendor-panel/refund`}
                        className={`flex gap-1 [&>*]:my-auto 2xl:text-base xl:text-xs hover:border-b hover:border-solid hover:border-b-yellow-500 2xl:pl-4 xl:pl-4 2xl:py-4 xl:py-3.5 border-b-[1px] border-b-white/10`}
                    >
                        <div className="text-xl flex [&>*]:mx-auto w-[30px]">
                            <img loading="lazy" src='/assets/icons/side-bar/money.svg' alt="" />
                        </div>
                        <div>Refund</div>
                    </Link>

                    <Link onClick={handleCloseMenu}
                        to={`/vendor-panel/faqs`}
                        className={`flex gap-1 [&>*]:my-auto 2xl:text-base xl:text-xs hover:border-b hover:border-solid hover:border-b-yellow-500 2xl:pl-4 xl:pl-4 2xl:py-4 xl:py-3.5 border-b-[1px] border-b-white/10`}
                    >
                        <div className="text-xl flex [&>*]:mx-auto w-[30px]">
                            <img loading="lazy" src='/assets/icons/side-bar/need-help.svg' alt="" />
                        </div>
                        <div>FAQs</div>
                    </Link>

                    <button type='button'
                        onClick={() => {
                            localStorage.removeItem("token");
                            window.location.assign('/')
                        }}
                        className={`flex gap-1 border-none outline-none bg-transparent [&>*]:my-auto 2xl:text-base xl:text-xs hover:border-b hover:border-solid hover:border-b-yellow-500 2xl:pl-6 xl:pl-4 2xl:py-4 xl:py-3.5 border-b-[1px] border-b-white/10`}
                    >
                        <div className="text-xl flex [&>*]:mx-auto w-[30px]">
                            <img loading="lazy" src='/assets/icons/side-bar/log-out.svg' alt="" />
                        </div>
                        <div>Logout</div>
                    </button>
                </div>
            </div>
            {show ? <ModalOverlay /> : <></>}
        </>
    )
}

// ======= Admin Pannel Sidebar =====

const AdminSidebar = ({ show, setter }: any) => {
    // const router = useHistory();
    // const router = useNavigation()
    // Define our base class
    const className = "bg-white w-[290px] transition-[margin-left] ease-in-out duration-500 fixed md:static top-0 bottom-0 left-0 z-40";
    // Append class based on state of sidebar visiblity
    const appendClass = show ? " ml-0" : " ml-[-340px] md:ml-0";

    // Clickable menu items
    const MenuItem = ({ icon, name, route }: any) => {

        // Highlight menu item based on currently displayed route
        // const colorClass = router.location === route ? "text-white" : "text-white/50 hover:text-white";

        return (
            <>
                <Link
                    to={route}
                    onClick={() => {
                        setter((oldVal: any) => !oldVal);
                    }}
                    className={`flex gap-1 [&>*]:my-auto 2xl:text-base xl:text-xs hover:border-b hover:border-solid hover:border-b-yellow-500 2xl:pl-4 xl:pl-4 2xl:py-4 xl:py-3.5 border-b-[1px] border-b-white/10`}
                >
                    <div className="text-xl flex [&>*]:mx-auto w-[30px]">
                        {icon}
                    </div>
                    <div>{name}</div>
                </Link>
            </>
        )
    }

    const SubMenuItem = ({ icon, name, arrowIcon, route, subMenus }: SubMenuItemInterface) => {
        const [showSubMenu, setshowSubMenu] = useState(false)
        return (
            <>
                <Link
                    to={route}
                    onClick={() => {
                        setshowSubMenu((oldVal: any) => !oldVal);
                    }}
                    className={`flex gap-1 [&>*]:my-auto 2xl:text-base xl:text-xs 2xl:pl-5 xl:pl-4 2xl:py-4 xl:py-3.5 border-b-[1px] border-b-white/10`}
                >
                    <div className="text-xl flex [&]*:mx-auto w-[30px]">
                        {icon}
                    </div>
                    <div className=''>{name}</div>
                    <div>{showSubMenu ? <IoIosArrowUp className='text-amber-300 text-xl font-bold' /> : arrowIcon}</div>
                </Link>

                {showSubMenu && (
                    <ul className='flex flex-col border-l border-dashed border-neutral-300'>
                        {subMenus.map((data: any) => (
                            <Link to={data.route}
                                className={`flex gap-1 mb-1.5 [&>*]:my-auto ml-5 2xl:text-base xl:text-xs 2xl:pl-6 xl:pl-4 2xl:py-1.5 xl:py-1.5 border-b-[1px] border-b-white/10`}
                            >
                                <div className='text-sm text-neutral-500 hover:text-black'>{data.name}</div>
                            </Link>
                        ))}

                    </ul>
                )}
            </>
        )
    }

    // Overlay to prevent clicks in background, also serves as our close button
    const ModalOverlay = () => (
        <div
            className={`flex md:hidden fixed top-0 right-0 bottom-0 left-0 bg-black/50 z-30`}
            onClick={() => {
                setter((oldVal: any) => !oldVal);
            }}
        />
    )

    return (
        <>
            <div className={`${className}${appendClass}`}>
                <div className="p-2 flex">
                    <Link to="/">
                        {/*eslint-disable-next-line*/}
                        <img loading="lazy" src="/assets/images/logo/logo.png" alt="" />
                    </Link>
                </div>
                <div className="p-2 flex flex-col gap-1.5 items-center">
                    <Link to="/">
                        {/*eslint-disable-next-line*/}
                        <img loading="lazy" src="/assets/images/admin-profile.png" alt="" />
                    </Link>
                    <h4 className='text-base font-medium'>
                        Hector Micheal
                    </h4>
                    <h6>
                        @Username
                    </h6>
                </div>
                <div className="flex flex-col mt-6 2xl:pl-5 xl:pl-0">
                    <MenuItem
                        name="Profile"
                        route="/admin-profile"
                        icon={<img loading="lazy" src='/assets/icons/side-bar/profile.svg' alt="" />}
                    />
                    <SubMenuItem
                        name="Shops"
                        subMenus={[
                            { name: "All Shops", route: "/all-shops" },
                            { name: "Add New Shop", route: "/add-new-shop" },
                            { name: "My Shops", route: "/view-shop" },
                        ]}
                        route="#"
                        icon={<img loading="lazy" src='/assets/icons/side-bar/shop.svg' alt="" />}
                        arrowIcon={<IoIosArrowDown className='text-xl' />}
                    />
                    <SubMenuItem
                        name="Products"
                        subMenus={[
                            { name: "All Product", route: "/shop-management" },
                            { name: "My Draft Product", route: "/add-new-shop" },
                            { name: "All Low and Out of stock Products", route: "" }
                        ]}
                        route="#"
                        icon={<img loading="lazy" src='/assets/icons/side-bar/product-management.svg' alt="" />}
                        arrowIcon={<IoIosArrowDown className='text-xl' />}
                    />
                    <MenuItem
                        name="Inventory"
                        route="/inventory"
                        icon={<img loading="lazy" src='/assets/icons/side-bar/dollar.svg' alt="" />}
                    />
                    <MenuItem
                        name="Categories"
                        route="/inventory"
                        icon={<img loading="lazy" src='/assets/icons/side-bar/dollar.svg' alt="" />}
                    />
                    <MenuItem
                        name="Tags"
                        route="/inventory"
                        icon={<img loading="lazy" src='/assets/icons/side-bar/dollar.svg' alt="" />}
                    />
                    <MenuItem
                        name="Attributes"
                        route="/inventory"
                        icon={<img loading="lazy" src='/assets/icons/side-bar/dollar.svg' alt="" />}
                    />
                    <MenuItem
                        name="Manufactures/Publications"
                        route="/inventory"
                        icon={<img loading="lazy" src='/assets/icons/side-bar/dollar.svg' alt="" />}
                    />

                    <MenuItem
                        name="Taxes"
                        route="/inventory"
                        icon={<img loading="lazy" src='/assets/icons/side-bar/dollar.svg' alt="" />}
                    />

                    <MenuItem
                        name="Shippings"
                        route="/inventory"
                        icon={<img loading="lazy" src='/assets/icons/side-bar/dollar.svg' alt="" />}
                    />
                    <MenuItem
                        name="Withdrawals"
                        route="/inventory"
                        icon={<img loading="lazy" src='/assets/icons/side-bar/dollar.svg' alt="" />}
                    />
                    <SubMenuItem
                        name="Refunds"
                        subMenus={[
                            { name: "Reported Refunds", route: "/shop-management" },
                            { name: "Refund Policies", route: "/add-new-shop" },
                            { name: "Add new refund policy", route: "" },
                            { name: "Refund Reasons", route: "" },
                            { name: "Add new refund reason", route: "" }
                        ]}

                        route="#"
                        icon={<img loading="lazy" src='/assets/icons/side-bar/product-management.svg' alt="" />}
                        arrowIcon={<IoIosArrowDown className='text-xl' />}
                    />
                    <MenuItem
                        name="Orders"
                        route="/inventory"
                        icon={<img loading="lazy" src='/assets/icons/side-bar/dollar.svg' alt="" />}
                    />
                    <MenuItem
                        name="Transaction"
                        route="/inventory"
                        icon={<img loading="lazy" src='/assets/icons/side-bar/dollar.svg' alt="" />}
                    />
                    <MenuItem
                        name="All Users"
                        route="/inventory"
                        icon={<img loading="lazy" src='/assets/icons/side-bar/dollar.svg' alt="" />}
                    />
                    <MenuItem
                        name="Admin List"
                        route="/inventory"
                        icon={<img loading="lazy" src='/assets/icons/side-bar/dollar.svg' alt="" />}
                    />
                    <SubMenuItem
                        name="Vendors"
                        subMenus={[
                            { name: "All Vendors", route: "/shop-management" },
                            { name: "Pending Vendors", route: "/add-new-shop" }
                        ]}

                        route="#"
                        icon={<img loading="lazy" src='/assets/icons/side-bar/product-management.svg' alt="" />}
                        arrowIcon={<IoIosArrowDown className='text-xl' />}
                    />
                    <MenuItem
                        name="Customer"
                        route="/inventory"
                        icon={<img loading="lazy" src='/assets/icons/side-bar/dollar.svg' alt="" />}
                    />
                    <MenuItem
                        name="Reviews"
                        route="/inventory"
                        icon={<img loading="lazy" src='/assets/icons/side-bar/dollar.svg' alt="" />}
                    />
                    <MenuItem
                        name="Questions"
                        route="/inventory"
                        icon={<img loading="lazy" src='/assets/icons/side-bar/dollar.svg' alt="" />}
                    />
                    <SubMenuItem
                        name="Coupons"
                        subMenus={[
                            { name: "All Coupons", route: "/shop-management" },
                            { name: "Add new coupon", route: "/add-new-shop" }
                        ]}

                        route="#"
                        icon={<img loading="lazy" src='/assets/icons/side-bar/product-management.svg' alt="" />}
                        arrowIcon={<IoIosArrowDown className='text-xl' />}
                    />
                    <SubMenuItem
                        name="FAQs"
                        subMenus={[
                            { name: "All FAQs", route: "" },
                            { name: "Add new FAQ", route: "" }
                        ]}

                        route="#"
                        icon={<img loading="lazy" src='/assets/icons/side-bar/product-management.svg' alt="" />}
                        arrowIcon={<IoIosArrowDown className='text-xl' />}
                    />
                    <SubMenuItem
                        name="Settings"
                        subMenus={[
                            { name: "General Settings", route: "" },
                            { name: "Payment Settings", route: "" },
                            { name: "SEO Settings", route: "" },
                            { name: "Event Settings", route: "" },
                            { name: "Shop Settings", route: "" },
                            { name: "Company Information", route: "" },
                            { name: "Promo Popup", route: "" },
                        ]}

                        route="#"
                        icon={<img loading="lazy" src='/assets/icons/side-bar/product-management.svg' alt="" />}
                        arrowIcon={<IoIosArrowDown className='text-xl' />}
                    />

                    <MenuItem
                        name="Logout"
                        route="/contact"
                        icon={<img loading="lazy" src='/assets/icons/side-bar/log-out.svg' alt="" />}
                    />

                </div>
            </div>
            {show ? <ModalOverlay /> : <></>}
        </>
    )
}


export { Sidebar, VendorSidebar, AdminSidebar };