import React from 'react'
// import Swiper core and required modules

import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import { useSalesfrontCollectionsQuery } from '../../../../features/api/products-api';
 const ShopByCategorySection = () => {
    const { isLoading, data } = useSalesfrontCollectionsQuery();

    return (
        <>
            <div className='bg-black w-full py-8 mt-8'>
                <div className='flex justify-center'>
                    <h3 className='text-xl font-bold text-white'>
                        Shop by Categories
                    </h3>
                </div>
                <div className='max-w-screen-2xl mx-auto 2xl:px-0 px-3 pt-7'>
                    <Swiper
                        // install Swiper modules
                        spaceBetween={0}
                        slidesPerView={8}
                        className='text-white'
                        breakpoints={{
                            0: {
                                slidesPerView: 3,
                            },
                            400: {
                                slidesPerView: 4,
                            },
                            639: {
                                slidesPerView: 4,
                            },
                            865: {
                                slidesPerView: 4
                            },
                            1000: {
                                slidesPerView: 5
                            },
                            1500: {
                                slidesPerView: 10
                            },
                            1700: {
                                slidesPerView: 10
                            }
                        }}
                    >
                        {
                            isLoading ? Array(8).fill(0)?.map((item: number, index: number) => (
                                <SwiperSlide key={index}>
                                    <div className='flex flex-col gap-4 items-center'>
                                        <div className='flex justify-center items-center 2xl:size-20 xl:size-20 size-14 bg-white p-3 rounded-full'>
                                            <div className='w-full h-full rounded-full animate-pulse bg-gray-300' />
                                        </div>
                                        <div className='text-center 2xl:text-sm xl:text-sm text-xs font-medium'>
                                            <div className='w-20 h-3 bg-gray-300 animate-pulse rounded-full' />
                                        </div>
                                    </div>
                                </SwiperSlide>
                            )) : data?.collections?.map((item: any) => {
                                const searchQuery = item.handle === 'frontpage' ? 'women' : item.handle;
                                return (
                                    <SwiperSlide key={item.id}>
                                        <Link to={`/shop?search=${searchQuery}`} className='flex flex-col gap-4 items-center'>
                                            <div className='flex justify-center items-center 2xl:size-20 xl:size-20 size-14 bg-white p-0 rounded-full'>
                                                <img loading='lazy' src={item.image} alt={item.title} className='w-full h-full object-cover rounded-full' />
                                            </div>
                                            <div className='text-center 2xl:text-base xl:text-base text-xs font-medium'>
                                                {item.title}
                                            </div>
                                        </Link>
                                    </SwiperSlide>
                                )
                            })}
                    </Swiper>
                </div>
            </div>
        </>
    )
}
export default ShopByCategorySection;