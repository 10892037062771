import React, { useEffect, useState } from 'react'
import { useToast } from '../../shared/ToastContext';
import { BsStar } from 'react-icons/bs';
import { FaStar } from 'react-icons/fa';

interface Review {
    id: number;
    text: string;
}


export default function ProductDetailsTab({
    product,

}: any) {
    const [activeTab, setActiveTab] = useState(1);
    const { showToast } = useToast();
    const [review, setReview] = useState('');
    const [reviews, setReviews] = useState<string[]>([]);

    const stars = Array(5).fill(false).map((_, index) => index < 5);
    // Load reviews from local storage when the component mounts
    useEffect(() => {
        const savedReviews = JSON.parse(localStorage.getItem('reviews') || '[]') || [];
        setReviews(savedReviews);
    }, []);

    // Save reviews to local storage whenever the reviews array changes
    useEffect(() => {
        localStorage.setItem('reviews', JSON.stringify(reviews));
    }, [reviews]);

    const handleReviewChange = (e: any) => {
        setReview(e.target.value);
    };

    const handleReviewSubmit = () => {
        if (review.trim()) {
            setReviews((prevReviews) => [...prevReviews, review]);
            setReview(''); // Clear the input after submission
            showToast("Your Review is Updated", "success", "Review Updated");
        } else {
            showToast("Please enter a review", "error", "Review Not Updated");
        }
    };

    // Function to render content based on the active tab
    const renderContent = () => {
        switch (activeTab) {
            case 0:
                return <div className='w-full shopify-description'>
                    <div dangerouslySetInnerHTML={{ __html: product?.body_html }} />
                </div>
            case 1:
                return <div className='w-full mt-4'>
                    <ul>
                        {reviews.map((rev, index) => (
                            <li key={index} className='pb-2 mb-2'>
                                <div className='flex flex-row items-center gap-x-2 pb-3'>
                                    {stars.map((filled, index) => (
                                        <FaStar key={index} className={filled ? 'text-primary' : 'text-gray-300'} />
                                    ))}
                                </div>
                                {rev}
                            </li>
                        ))}
                    </ul>
                    <h1 className='text-2xl font-medium'>
                        Add a Review
                    </h1>
                    <form className='mt-5' onSubmit={(e) => e.preventDefault()}>
                        <textarea name="review" className='w-full resize-none border text-sm border-solid border-gray-300 rounded-md p-3 outline-none' rows={5} cols={6} id="" placeholder='Please enter Description' value={review}
                            onChange={handleReviewChange}></textarea>
                        <button type="button" onClick={handleReviewSubmit} className='px-4 py-2 bg-primary text-white text-base font-medium rounded-lg mt-4'>Submit</button>
                    </form>

                </div>;
            default:
                return null;
        }
    };
    return (
        <>
            <div className="pt-14 border-b border-solid border-gray-300 pb-2">
                <div className='flex items-center 2xl:gap-x-28 xl:gap-x-28 gap-x-5'>
                    <button type='button' onClick={() => setActiveTab(0)} className={`relative outline-none bg-transparent font-normal 2xl:text-lg xl:text-lg md:text-lg lg:text-lg text-sm ${activeTab === 0 ? "after:contents after:absolute after:bottom-0 after:right-0 after:left-0 after:w-full after:h-2 after:bg-black" : "text-gray-400"}`}>
                        Product Specifications
                    </button>
                    <button type='button' onClick={() => setActiveTab(1)} className={`border-none outline-none font-normal 2xl:text-lg xl:text-lg md:text-lg lg:text-lg text-sm bg-transparent ${activeTab === 1 ? "" : "text-gray-400"}`}>
                        Customer Reviews
                    </button>
                </div>
            </div>

            {/* Tab content */}
            <div className="tab-content">
                {renderContent()}
            </div>
        </>
    )
}
