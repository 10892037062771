import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useToast } from '../../../components/shared/ToastContext';
import { useGetProfileQuery } from '../../../features/slices/api-slices';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { LuPhoneCall } from 'react-icons/lu';
import { TbMessages } from 'react-icons/tb';
import { MdOutlineMail } from "react-icons/md";
import { FaSquareInstagram } from "react-icons/fa6";
import { FaFacebookSquare } from 'react-icons/fa';
import { BsTwitterX } from 'react-icons/bs';
import Footer from '../../../components/common/footer/footer';
import { IoLocationOutline } from 'react-icons/io5';
import MainHeader from '../../../components/common/header/main-header';
import { useGetBusinessByIdQuery, useGetBusinessesQuery } from '../../../features/slices/vendor-api';
import { getCouponAddress } from '../../../utils/utils';
import SekeltonLoader from '../../../components/common/ui/sekelton';

export default function BusinessProfileDetailsPage() {
    const { id } = useParams();
    const { showToast } = useToast();
    const { data, error, isLoading } = useGetBusinessByIdQuery(id, {
        skip: !id
    });
    useEffect(() => {
        if (error) {
            showToast('Failed to fetch profile data', "error", "Failed to fetch profile data")
        }
    }, [error]);


    return (
        <>
            <MainHeader />
            {isLoading ? (
                <SekeltonLoader />
            ) : (
                <>
                    <section className='max-w-screen-2xl mx-auto 2xl:px-0 xl:px-16 px-5'>
                        <div className='flex flex-wrap items-center gap-3 pt-6'>
                            <Link to={`/`} className='border-b border-solid border-black'>Home</Link>
                            <p>
                                /
                            </p>
                            <Link to={`/`} className='border-b border-solid border-black'>Business Directories</Link>
                            <p>
                                /
                            </p>
                            <Link to={`/`} className='border-b border-solid border-gray-500 text-gray-500'> {data?.businessName}</Link>
                        </div>
                    </section>
                    {/* ======= Profile details ======= */}

                    <div className='max-w-screen-2xl mx-auto 2xl:px-0 xl:px-16 px-5 pb-32'>
                        <div className='w-full p-8 px-0 bg-white rounded-2xl'>
                            <div className='w-full relative h-auto'>
                                {data?.backgroundImage ? (
                                    <img loading="lazy"
                                        src={data?.backgroundImage}
                                        alt='BannerImage'
                                        className='w-full 2xl:h-56 xl:h-56 md:h-56 lg:h-56 h-20 object-cover rounded-2xl'
                                    />
                                ) : (
                                    <div className='w-full 2xl:h-56 xl:h-56 md:h-56 lg:h-56 h-20 object-cover bg-gray-200 rounded-2xl'></div>
                                )}


                                <div className='absolute 2xl:top-32 xl:top-32 -bottom-10 2xl:border-none xl:border-none md:border-none lg:border-none border border-solid border-gray-200 p-0.5 bg-gray-200 2xl:left-8 xl:left-8 md:left-8 lg:left-8 left-2 2xl:size-44 xl:size-44 md:size-32 lg:size-32 size-24 rounded-full flex items-center justify-center'>
                                    {data?.profile ? (
                                        <img loading="lazy" src={`${data?.profile}`} className="rounded-full 2xl:size-40 xl:size-44 size-32" alt="Avatar" />
                                    ) : (
                                        <img loading="lazy" src={`/assets/images/business-img.png`} className="rounded-full 2xl:size-40 xl:size-44 size-20" alt="Avatar" />
                                    )}
                                </div>
                                <div className='absolute 2xl:-bottom-14 xl:-bottom-14 md:-bottom-14 lg:-bottom-14 -bottom-28 2xl:left-56 xl:left-56 md::left-56 left-0'>
                                    <div className='flex flex-col items-start'>
                                        <h4 className='capitalize text-2xl font-medium'>
                                            {data?.businessName}
                                        </h4>
                                        <p className='text-xs text-gray-500 mt-1'>
                                            Business Owner: {data?.vendorId?.name ? (
                                                data?.vendorId?.name
                                            ) : (
                                                'Not Provided'
                                            )}

                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='grid 2xl:grid-cols-4 xl:grid-cols-4 grid-col-1 gap-6 2xl:mt-28 xl:mt-28 md:mt-28 lg:mt-28 mt-36'>
                                {data && (
                                    <>
                                        <div className='w-full border border-solid border-gray-200 rounded-xl p-4'>
                                            <p className='text-lg'>
                                                Address: <span className='text-sm'>{getCouponAddress(data)}</span>
                                            </p>
                                            <button type="button" className='w-fit px-6 py-2 mt-4 border border-solid border-black uppercase rounded flex items-center gap-3 text-sm'>
                                                <HiOutlineLocationMarker className='text-lg' />
                                                View Map
                                            </button>
                                        </div>
                                        <div className='w-full border border-solid border-gray-200 rounded-xl p-4'>
                                            <p className='text-lg'>
                                                Contact: {data.phone}
                                            </p>

                                            <CallUsButton phoneNumber={data.phone} message="Hello! I would like to know more about your services."  />

                                        </div>
                                        <div className='w-full border border-solid border-gray-200 rounded-xl p-4'>
                                            <p className='text-lg'>
                                                Email: {data.email}
                                            </p>
                                            <button type="button" className='w-fit px-6 py-2 mt-4 border border-solid border-black uppercase rounded flex items-center gap-3 text-sm'>
                                                <TbMessages className='text-lg' />
                                                Message Now
                                            </button>
                                        </div>
                                        <div className='w-full border border-solid border-gray-200 rounded-xl p-4'>
                                            <p className='text-lg'>
                                                Follow Us
                                            </p>
                                            <div className='flex items-center gap-3 pt-3'>
                                                <Link to={`https://www.facebook.com/damndealinc`}>
                                                    <img src="/assets/images/svg/facebook.svg" alt="" />
                                                </Link>
                                                <Link to={`https://www.instagram.com/damndeal2020`}>
                                                    <img src="/assets/images/svg/instagram.svg" alt="" />
                                                </Link>
                                                <Link to={`https://x.com/damndealinc`}>
                                                    <img src="/assets/images/svg/twitter.svg" alt="" />
                                                </Link>
                                            </div>
                                        </div>
                                    </>
                                )}

                            </div>

                            <p className='2xl:text-sm xl:text-sm text-sm mt-8'>
                                <span className='font-bold'>Description:</span> {data?.description}
                            </p>
                        </div>

                        {/* ======= other business profile details ======= */}
                        <div className='grid 2xl:grid-cols-2 xl:grid-cols-2 gap-5'>
                            <div className='w-full border border-solid border-gray-200 rounded-lg p-5'>
                                <p>
                                    Business Owner Details
                                </p>
                                {data && (
                                    <>
                                        <div className='flex 2xl:flex-row xl:flex-row md:flex-row lg:flex-row flex-col items-start gap-5 pt-4'>
                                            {data?.avatar ? (
                                                <img loading="lazy" src={`${data?.avatar}`} className="rounded-full size-56" alt="Avatar" />
                                            ) : (
                                                <img loading="lazy" src={`/assets/images/business-img.png`} className="rounded size-32" alt="Avatar" />
                                            )}
                                            <div className='flex flex-col gap-3.5'>
                                                <h3 className='text-xl'>{data.name}</h3>
                                                <div className='flex gap-x-2 items-center'>
                                                    <MdOutlineMail />
                                                    <p className='text-sm'>
                                                        {data.email}
                                                    </p>
                                                </div>
                                                <div className='flex gap-x-2 items-center'>
                                                    <HiOutlineLocationMarker />
                                                    <p className='text-sm'>
                                                        {data?.vendorId ? (
                                                            <>
                                                                {getCouponAddress(data?.vendorId)}
                                                            </>
                                                        ) : (
                                                            'No address provided.'
                                                        )}

                                                    </p>
                                                </div>
                                                <div className='flex gap-x-2 items-center'>
                                                    <LuPhoneCall />
                                                    <p className='text-sm'>
                                                        {data.phone}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <p className='text-sm mt-3'>
                                            <strong>Owner Bio:</strong> {data.vendorId?.bio?.slice(0, 200)}
                                        </p>
                                        <div className='grid 2xl:grid-cols-3 xl:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 grid-cols-1 gap-3 pt-4'>
                                            <button type="button" disabled={!data?.vendorId?.avatar} className='w-full px-3 disabled:cursor-not-allowed disabled:bg-gray-100 disabled:border-none disabled:text-gray-400 py-2 border border-solid border-black rounded-lg text-sm flex justify-center uppercase items-center gap-2'>
                                                <FaSquareInstagram />
                                                Instagram
                                            </button>
                                            <button type="button" disabled={!data?.vendorId?.avatar} className='w-full disabled:cursor-not-allowed disabled:bg-gray-100 disabled:border-none disabled:text-gray-400 px-3 py-2 border border-solid border-black rounded-lg text-sm flex justify-center uppercase items-center gap-2'>
                                                <FaFacebookSquare />
                                                Facebook
                                            </button>
                                            <button type="button" disabled={!data?.vendorId?.avatar} className='w-full disabled:cursor-not-allowed disabled:bg-gray-100 disabled:border-none disabled:text-gray-400 px-3 py-2 border border-solid border-black rounded-lg text-sm flex justify-center uppercase items-center gap-2'>
                                                <BsTwitterX />
                                                twitter
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                            {/* ======= Recommended Section ======= */}
                            <RecommendedSection />
                        </div>

                        <h1 className='text-xl mt-10 font-bold'>
                            Our Coupons & Special Offers
                        </h1>
                    </div>
                </>
            )}
            <Footer />
        </>
    )
}




export const RecommendedSection = () => {
    const { data } = useGetBusinessesQuery();

    console.log(data);

    return (
        <>
            <div className='w-full border border-solid border-gray-200 rounded-lg p-5'>
                <p>
                    Recommended Stores
                </p>
                {data?.slice(0, 3)?.map((item: any) => (
                    <div className='flex 2xl:flex-row xl:flex-row md:flex-row lg:flex-row flex-col gap-5 justify-between 2xl:items-center xl:items-center md:items-center lg:items-center items-start pt-6'>
                        <div className='flex 2xl:flex-row xl:flex-row md:flex-row lg:flex-row flex-col gap-4 w-4/5 items-start'>
                            {item?.profile ?
                                <img loading='lazy' src={item.profile} className='size-28' alt="" />
                                :
                                <div className='w-28 h-28 border border-solid border-gray-300 flex justify-center items-center'>
                                    <span className='text-black font-bold text-2xl'>{item?.businessName?.substr(0, 2)?.toUpperCase()}</span>
                                </div>
                            }
                            <div className='flex flex-col gap-2'>
                                <h3 className='text-base'>{item?.businessName}</h3>
                                <p className='text-sm text-gray-500'>
                                    {item?.description ? (
                                        <>
                                            {item?.description?.slice(0, 80)}
                                        </>
                                    ) : (
                                        'No description provided.'
                                    )}

                                </p>
                                <div className='flex 2xl:flex-row xl:flex-row md:flex-row lg:flex-row flex-col  2xl:items-center xl:items-center md:items-center lg:items-center items-start gap-3'>
                                    <div className='flex 2xl:flex-row xl:flex-row md:flex-row lg:flex-row flex-col  2xl:items-center xl:items-center md:items-center lg:items-center items-start 2xl:gap-8 xl:gap-8 md:gap-8 lg:gap-8 gap-3 pt-1'>
                                        <div className='flex gap-2 items-center'>
                                            <IoLocationOutline className='text-lg' />
                                            <p className='text-sm'>
                                                {item?.address1}
                                            </p>
                                        </div>
                                        <div className='flex gap-4 items-center'>
                                            <LuPhoneCall className='text-lg' />
                                            <p className='text-sm'>
                                                {item?.phone}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Link to={`/business-profile/${item?._id}`} className='text-sm underline'>View Profile</Link>
                    </div>
                ))}
            </div>
        </>
    )
}



export const CallUsButton = ({ phoneNumber, message }: any) => {
    const handleClick = () => {
        const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(url, '_blank');
    };

    return (
        <button type="button" onClick={handleClick} className='w-fit px-6 py-2 mt-4 border border-solid border-black uppercase rounded flex items-center gap-3 text-sm'>
            <LuPhoneCall className='text-lg' />
            Call Now
        </button>
    );
}