import React from 'react'
import MainHeader from '../../../components/common/header/main-header'
import { useNavigate } from 'react-router-dom'
import ProductSkeleton from '../../../components/shared/skeleton/ProductSkeleton'
import { useGetAllCouponsQuery } from '../../../features/slices/api-slices'
import Footer from '../../../components/common/footer/footer'
import { useSelector } from 'react-redux'
import { AppState } from '../../../redux/store'

export default function BuyEbboksPage() {
    const { isLoading, data } = useGetAllCouponsQuery()
    const user = useSelector((state: AppState) => state.auth.user)
    const navigate = useNavigate();
    const backgrounds = ['#FFBB38', '#FFEFD1', '#FAFFC1', '#FFFACE']

    const handleNavigate = () => {
        if (user) navigate('/ebook/checkout')
        else navigate('/auth/signin?redirect=/ebook/checkout')
    }
    return (
        <>
            <MainHeader />

            <main className='max-w-screen-2xl mx-auto 2xl:px-0 xl:px-16 px-5 pt-12 mb-20'>
                <div className='flex justify-between items-center pb-6'>
                    {/* {Write good SEO and user friendly content here to tell them that they can buy e book to get discount coupons} */}
                    <h1 className='text-3xl font-medium max-w-2xl'>
                        Buy E-Books to get Following Discount Coupons and Much More
                    </h1>
                    <button
                        type='button'
                        onClick={() => handleNavigate()}
                        className={`px-6 mt-4 py-2 text-black text-sm tracking-wider rounded-md bg-primary`}
                    >
                        Shop Now
                    </button>
                </div>
                <div className='grid 2xl:grid-cols-5 xl:grid-cols-5 md:grid-cols-4 lg:grid-cols-4 grid-cols-1 gap-4 max-h-[70vh] overflow-y-auto'>
                    {isLoading ?
                        // Add skeletons here
                        <>
                            {Array(4).fill(0).map((_, i) => (
                                <ProductSkeleton key={i} />
                            ))}
                        </>
                        : (
                            <>
                                {data
                                    ?.filter((item: any) => item?.vendorPermission?.status === "approved")
                                    ?.map((item: any, index: number) => (
                                        <div key={item.image}>
                                            <DealCard
                                                title={item.productTitle}
                                                image={item.image}
                                                discount={item.discount}
                                                background={backgrounds[index]}
                                                link={"/buy-coupons"}
                                                address={item.businessId}
                                                key={item._id} />
                                        </div>
                                    ))
                                }
                            </>
                        )
                    }
                </div>
            </main>

            <Footer />
        </>
    )
}


// param 1: title, param 2: image, param 3: discount, param 4: category, param 5: link, param 6: button text, param 7: bg color, param 8: button color
type DealCardProps = {
    title: string;
    image: string;
    discount: string;
    background: string;
    link: string;
    address?: any;
}

export const DealCard = ({ title, image, background, link, address }: DealCardProps) => {
    const className = `w-full rounded-xl relative 2xl:min-h-80 xl:min-h-80 min-h-72`;

    return (
        <div className={className} style={{
            backgroundImage: `url(${image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '100%',
            position: 'relative'
        }}>
            {/* Overlay for opacity effect */}
            <div className='absolute inset-0 bg-black opacity-55 rounded-xl'></div>

            {/* Content */}
            <div className='relative p-6'>
                <h3 className='2xl:text-2xl xl:text-2xl text-sm font-bold text-white'>{title}</h3>
            </div>
            {address && <div className='absolute bottom-4 left-4 text-white'>
                <p className='font-bold'>{address?.businessName}</p>
                <p>{address?.address1}</p>
                {address?.address2 && <p>{address?.address2}</p>}
                <p>{address?.city + ", " + address?.state + ", " + address?.zipcode}</p>
            </div>}
        </div>
    );
};