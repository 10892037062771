import React from 'react'
import { Footer, MainHeader } from '../../components'
import { useGetPoolsQuery, useJoinPoolMutation } from '../../features/api/pool-api'
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from '../../redux/store';
import { useToast } from '../../components/shared/ToastContext';



export default function JoinPoolsPage() {
    const navigate = useNavigate();
    const user = useSelector((state: AppState) => state.auth.user);
    const { data: pools, isLoading, error } = useGetPoolsQuery(undefined, {
        skip: !user
    });
    let state = useLocation().state;
    let order_value = state?.order_value || 0;
    let secret = state?.secret || '';

    if (!secret || !order_value) {
        navigate('/')
    }

    return (
        <div className='w-full h-full'>
            <MainHeader />

            <main className='w-full bg-center bg-cover bg-no-repeat h-[60vh] pools__bg relative'>
                <div className='w-1/2 p-8 absolute right-0 top-0 bottom-0 h-full linear__gradient'>
                    <div className='flex justify-center items-center h-full w-full'>
                        <h1 className='2xl:text-5xl xl:text-5xl font-bold 2xl:w-2/3 xl:2/3 text-xl w-full 2xl:!leading-[65px] xl:!leading-[65px] md:!leading-[65px] lg:!leading-[65px] leading-6'>
                            Join Our Pools and Score Big Prizes!
                        </h1>
                    </div>
                </div>
            </main>

            {/* ======= Poools Data Content ========= */}
            <section className='max-w-screen-2xl mx-auto 2xl:px-0 xl:px-0 lg:px-0 md:px-0 pb-16 mt-16'>
                {isLoading && (
                    <div className='h-96 grid grid-cols-2 gap-10'>
                        {[1, 2, 3, 4].map((item) => (
                            <div key={item} className='w-full p-6 border border-solid border-gray-200 rounded-xl'>
                            </div>
                        ))}
                    </div>
                )}
                {pools && pools.length === 0 && (
                    <div className='flex justify-center items-center h-96'>
                        <h1 className='text-2xl text-gray-500'>No Pools Available</h1>
                    </div>
                )}
                {error && (
                    <div className='flex justify-center items-center h-96'>
                        <h1 className='text-2xl text-gray-500'>Error Fetching Pools</h1>
                    </div>
                )}
                <div className='grid grid-cols-2 gap-10'>
                    {pools?.
                        filter((pool: any) => !pool.isClosed)?.
                        map((pool: any) => (
                            <PoolCard pool={pool} key={pool._id} />
                        ))}
                </div>
            </section>

            <Footer />
        </div>

    )
}


const PoolCard = ({
    pool
}: {
    pool: any
}) => {
    const user = useSelector((state: AppState) => state.auth.user);
    const { showToast } = useToast();
    const navigate = useNavigate();


    let state = useLocation().state;
    let order_value = state?.order_value || 0;

    const [join] = useJoinPoolMutation();

    const handleJoin = async (poolId: string) => {
        join({ poolId, userId: user?._id, orderValue: order_value }).unwrap()
            .then(() => {
                showToast("Pool joined successfully", "success", "Pool joined successfully");
                // navigate('/user-panel/my-pools')
            })
            .catch(() => {
                showToast("Error joining pool", "error", "Error joining pool");
            })
    }

    return <div key={pool._id} className='w-full p-6 border border-solid border-gray-200 rounded-xl'>
        <div className='flex 2xl:flex-row xl:flex-row md:flex-row lg:flex-row flex-col items-center gap-8'>
            {/* ========== left-side content ======== */}
            <div className='2xl:w-3/4 xl:w-3/4 md:w-3/4 lg:w-3/4 w-full'>
                <h4 className='2xl:text-xl xl:text-xl'>
                    {pool.name}
                </h4>
                <h6 className='2xl:text-sm xl:tsxt-sm text-gray-600 mt-3'>
                    Prize: {pool.prize}
                </h6>
                <p className='text-sm text-gray-500 mt-3'>
                    <span className='text-black'>Description:</span> {pool.description}
                </p>
                <h6 className='text-red-500 mt-3 text-sm'>
                    Participants: {pool.currentEntries} club have joined so far
                </h6>
                <button type="button" className='w-fit bg-primary px-14 rounded-lg mt-4 uppercase py-3 text-black text-sm border-none outline-none' onClick={() => handleJoin(pool._id)}> Join Now</button>
            </div>
            {/* ========= right-side image content -==-===== */}
            <div className='2xl:w-3/12 xl:w-4/12 md:w-4/12 lg:w-4/12'>
                <div className='relative size-44 rounded-full bg-gray-100'>
                    <img src={pool?.image} alt="" />
                </div>
            </div>
        </div>
    </div>
}

