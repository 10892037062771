import { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { useGetProfileQuery, useUpdateDataMutation } from '../../../features/slices/api-slices';
import { useToast } from '../../shared/ToastContext';
import { Link } from 'react-router-dom';

export default function ProfileSettingsAddressForm() {
    const { register, setValue, handleSubmit, formState: { errors } } = useForm();
    const { showToast } = useToast();
    const [updateData, { isLoading, isError }] = useUpdateDataMutation();
    const { data: user } = useGetProfileQuery();

    // const [files, setFiles] = useState<File[]>([]);

    // const handleFilesAdded = (newFiles: File[]) => {
    //     setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    // };

    const onSubmit = async (event: any) => {
        try {
            await updateData(event).unwrap();
            showToast("Data updated successfully!", "success", "Data updated");
        } catch (error) {
            showToast("Error updating data wrong", "error", "Error Occured");
        }
    };

    useEffect(() => {

        if (isError) {
            showToast("something went wrong", "error", "Something went wrong");
        }

        if (user) {
            setValue("address_1", user.address_1);
            setValue("address_2", user.address_2);
            setValue("state", user.state);
            setValue("city", user.city);
            setValue("zipcode", user.zip);
        }

    }, [user, setValue, isError]);

    return (
        <>
            <div className="w-full bg-white p-8 pt-5 mt-10 rounded-3xl">
                {isLoading ? (
                    <div className='flex justify-center items-center min-h-screen'>
                        <img loading="lazy" src="/assets/images/gif/spinner.svg" className='size-24' alt="" />
                    </div>
                ) : (
                    <>
                        <h2 className="text-black text-[22px] font-medium mt-6 leading-snug">Address</h2>
                        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)} className="grid 2xl:grid-cols-3 xl:grid-cols-3 grid-cols-1 gap-8 pt-8">
                            <div className="w-full relative 2xl:col-span-3 xl:col-span-3 md:col-span-3 lg:col-span-3 col-span-1">
                                <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.address_1 ? "text-red-500" : "text-zinc-800"}`}>Address 1 *</label>
                                <input type="text" {...register("address_1", { required: true })} placeholder="655 Shaniya Springs, Schowalterfort 46669" className={`w-full border border-solid border-gray-200 rounded-md outline-none h-14 px-4 ${errors.address_1 ? "border-red-500" : "border-gray-200"}`} />
                                {errors.address_1?.type === 'required' && <p role="alert" className="text-sm text-red-500 mt-2">Address 1 is required</p>}
                            </div>
                            <div className="w-full relative 2xl:col-span-3 xl:col-span-3 md:col-span-3 lg:col-span-3 col-span-1">
                                <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal text-zinc-800`}>Address 2 (Optional)</label>
                                <input type="text" {...register("address_2")} placeholder="96003 Florine Point, North Baronchester 05091-6744" className={`w-full border border-solid rounded-md outline-none h-14 px-4 border-gray-200`} />
                            </div>
                            <div className="w-full relative">
                                <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.state ? "text-red-500" : "text-zinc-800"}`}>State *</label>
                                <input type="text" {...register("state", { required: true })} placeholder="Port Estefania" className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.state ? "border-red-500" : "border-gray-200"}`} />
                                {errors.state?.type === 'required' && <p role="alert" className="text-sm text-red-500 mt-2">State is required</p>}
                            </div>
                            <div className="w-full relative">
                                <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.city ? "text-red-500" : "text-zinc-800"}`}>City/Town *</label>
                                <input type="text" {...register("city", { required: true })} placeholder="Hanford" className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.city ? "border-red-500" : "border-gray-200"}`} />
                                {errors.city?.type === 'required' && <p role="alert" className="text-sm text-red-500 mt-2">City Town is required</p>}
                            </div>
                            <div className="w-full relative">
                                <label htmlFor="" className={`text-sm absolute -top-2.5 left-5 bg-white font-normal ${errors.zipcode ? "text-red-500" : "text-zinc-800"}`}>ZIP Code *</label>
                                <input type="text" {...register("zipcode", { required: true })} placeholder="3233" className={`w-full border border-solid rounded-md outline-none h-14 px-4 ${errors.zipcode ? "border-red-500" : "border-gray-200"}`} />
                                {errors.zipcode?.type === 'required' && <p role="alert" className="text-sm text-red-500 mt-2">Zip code is required</p>}
                            </div>
                            <div className="flex justify-end gap-8 2xl:col-span-3 xl:col-span-3 md:col-span-3 lg:col-span-3 col-span-1">
                                <button type="submit" className="w-fit h-[46px] text-center text-zinc-800 text-xl font-medium leading-snug  2xl:px-10 xl:px-10 md:px-10 lg:px-10 px-8 py-3 bg-yellow-300 rounded justify-center items-center gap-2.5 inline-flex">
                                    Save
                                </button>
                                <Link type='button' to={`/user-panel/profile`} className="w-fit h-[46px]  2xl:px-10 xl:px-10 md:px-10 lg:px-10 px-8 py-3 bg-white text-center text-zinc-800 text-xl font-medium leading-snug rounded border border-zinc-800 justify-center items-center gap-2.5 inline-flex">
                                    Cancel
                                </Link>
                            </div>
                        </form>
                    </>
                )}
            </div>
        </>
    )
}
