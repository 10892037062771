import { useEffect, useState } from 'react'
import MenuBarMobile from '../components/common/sidebar/menu-bar-menu';
import { AdminSidebar, Sidebar, VendorSidebar } from '../components/common/sidebar/sidebar';
import Header from '../components/common/header/header';
import { Outlet, useNavigate } from 'react-router-dom';
import { useGetProfileQuery } from '../features/slices/api-slices';
import { useDispatch } from 'react-redux';
import { setUser } from '../features/slices/auth-slice';
import VendorHeader from '../components/common/header/vendor-header';
import { useToast } from '../components/shared/ToastContext';

// ========= Auth Layout ======================
const AuthLayout = ({ pageTitle, children }: any) => {
    // Concatenate page title (if exists) to site title
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // Mobile sidebar visibility state
    const { data, isLoading, isError } = useGetProfileQuery();
    console.log(data, isLoading, isError);
    const {showToast} = useToast();
    useEffect(() => {
        if (!isLoading && data) {
            dispatch(setUser(data));
        }
        else if (isError) {
            showToast("Please Login To Access this!", "error", "Login failed");
            navigate("/auth/signin");
        }
    }, [isLoading, data, navigate, isError, dispatch])

    return (
        <>
            {isLoading ? "" : data && (
               <Outlet />
            )}
        </>
    )
}
// ========= User Dashboard Layout Define =================
const DashboardLayout = ({ pageTitle, children }: any) => {
    // Concatenate page title (if exists) to site title
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // Mobile sidebar visibility state
    const [showSidebar, setShowSidebar] = useState(false);
    const { data, isLoading, isError } = useGetProfileQuery();
    console.log(data, isLoading, isError);
    const {showToast} = useToast();
    useEffect(() => {
        if (!isLoading && data) {
            dispatch(setUser(data));
            if (data?.role !== 0) {
                navigate("/");
            }
        }
        else if (isError) {
            showToast("Please Login To Access this!", "error", "Login failed");
            navigate("/auth/signin");
        }
    }, [isLoading, data, navigate, isError, dispatch])

    return (
        <>
            {isLoading ? "" : data && (
                <div className="min-h-screen">
                    <div className="flex gap-4">
                        <MenuBarMobile setter={setShowSidebar} />
                        <Sidebar show={showSidebar} setShowSidebar={setShowSidebar} setter={setShowSidebar} />
                        <div className="flex flex-col mt-[60px] lg:px-6 flex-grow w-full lg:m-3 pb-6 min-h-screen bg-center bg-cover bg-no-repeat __bg__banner">
                            <Header />
                            <Outlet />
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

// ========= Vendor Dashboard Layout Define =================
const VendorDashboardLayout = ({ pageTitle, children }: any) => {
    // Concatenate page title (if exists) to site title
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { data, isLoading, isError } = useGetProfileQuery();
    const { showToast } = useToast();
    useEffect(() => {
        if (!isLoading && data) {
            dispatch(setUser(data));
            if (data?.role !== 2) {
                navigate("/");
            }
        }
        else if (isError) {
            showToast("Please login account to Access this!", "error", "Login failed");
            navigate("/auth/signin");
        }
    }, [isLoading, data, navigate, dispatch, isError])

    // Mobile sidebar visibility state
    const [showSidebar, setShowSidebar] = useState(false);

    return (
        <>
            {
                isLoading ? "Loading" : data &&
                    <div className="min-h-screen">
                        <div className="flex gap-4">
                            <MenuBarMobile setter={setShowSidebar} />
                            <VendorSidebar show={showSidebar} setShowSidebar={setShowSidebar} setter={setShowSidebar} />
                            <div className="flex flex-col px-6 flex-grow w-screen md:w-full 2xl:m-6 xl:m-6 md:m-6 lg:m-6 m-0 pb-6 min-h-screen bg-center bg-cover bg-no-repeat __bg__banner bg-zinc-100 rounded-3xl">
                                <VendorHeader />
                                <Outlet />
                            </div>

                        </div>
                    </div>
            }
        </>
    )
}

// ========= Influencer Dashboard Layout Define =================
const InfluencerDashboardLayout = ({ pageTitle, children }: any) => {
   
    // Mobile sidebar visibility state
    const [showSidebar, setShowSidebar] = useState(false);

    return (
        <>
            <div className="min-h-screen">
                <div className="flex gap-4">
                    <MenuBarMobile setter={setShowSidebar} />
                    <Sidebar show={showSidebar} setShowSidebar={setShowSidebar} setter={setShowSidebar} />
                    <div className="flex flex-col px-6 flex-grow w-screen md:w-full 2xl:m-6 xl:m-6 md:m-6 lg:m-6 m-0 pb-6 min-h-screen bg-center bg-cover bg-no-repeat __bg__banner rounded-3xl">
                        <Header />
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    )
}


// ========= Admin Dashboard Layout Define =================
const AdminDashboardLayout = ({ pageTitle }: any) => {
 
    // Mobile sidebar visibility state
    const [showSidebar, setShowSidebar] = useState(false);

    return (
        <>

            <div className="min-h-screen">
                <div className="flex gap-4">
                    <MenuBarMobile setter={setShowSidebar} />
                    <AdminSidebar show={showSidebar} setter={setShowSidebar} />
                    <div className="flex flex-col px-6 flex-grow w-screen md:w-full 2xl:m-6 xl:m-6 md:m-6 lg:m-6 m-0 pb-6 min-h-screen bg-center bg-cover bg-no-repeat __bg__banner rounded-3xl">
                        <Header />
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    )
}


export { DashboardLayout, VendorDashboardLayout, AdminDashboardLayout, InfluencerDashboardLayout, AuthLayout }