import React, { Fragment, useEffect, useState } from "react";

interface ProductGalleryProps {
    images: any[];
    selectedVariant: any;
}

const ProductGallery: React.FC<ProductGalleryProps> = ({ images, selectedVariant }) => {
    console.log(images);

    const [mainImage, setMainImage] = useState(images?.[0]);
    const [selectedImage, setSelectedImage] = useState(0);

    const imagesGallery = [...images];
    useEffect(() => {
        setMainImage(images?.[0]);
    }, [images]);

    useEffect(() => {
        if (selectedVariant) {
            let variantImage = images.find(
                (item: any) => item?.id === selectedVariant?.image_id
            );
            setMainImage(variantImage || "");
        }
    }, [selectedVariant, images]);

    const imagesSide = imagesGallery?.length > 5 ? imagesGallery?.slice(0, 5) : imagesGallery;
    return (
        <Fragment>
            <div className="flex flex-col-reverse md:flex-row gap-8">
                <div className="flex flex-row md:flex-col gap-4 overflow-x-scroll scrollbar-hidden">
                    {imagesSide?.map((item: any, index) => (
                        <img
                            onClick={() => {
                                setMainImage(item);
                                setSelectedImage(index);
                            }}
                            key={index}
                            src={item?.src}
                            alt="product"
                            loading="lazy"
                            className={`w-[25vw] h-[15vh] md:w-[6vw] md:h-[auto] max-h-80 object-center cursor-pointer ${index === selectedImage ? "border-[3px] border-[#443297] opacity-60" : ""}`}
                        />
                    ))}
                </div>
                <div>
                    <img
                        src={mainImage?.src || images?.[0]?.src}
                        alt="product"
                        loading="lazy"
                        className="object-cover w-[100vw] h-[50vh] md:w-[30vw] md:h-auto"
                    />
                </div>
            </div>
        </Fragment>
    );
};

export default ProductGallery;
