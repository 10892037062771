import React from 'react'
import { AppState } from '../../redux/store'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { Link, useNavigate } from 'react-router-dom'
import { useGetMyEbooksQuery } from '../../features/api/ebook-api'
import DashboardBanner from '../../components/common/ui/banners/dashboard-banner'

export default function EDiscountCoupon() {
    const user = useSelector((state: AppState) => state.auth.user)
    const { isLoading, data, error } = useGetMyEbooksQuery(user?._id, {
        skip: !user?._id
    })

    const bannersUrls = ["/assets/images/banner3.png", "/assets/images/banner4.png"]

    return (
        <div className='w-full bg-white p-8 pt-0 lg:rounded-3xl'>
            {/* <h3 className="text-black text-[22px] font-medium leading-snug">E-Discount Coupon Book & Business Directories</h3> */}
            <DashboardBanner title='Buy Coupons in Bulk and Save Big!' image='/assets/images/buy-coupon-bg.png' />
            {
                isLoading ? (
                    <div className='w-full h-full flex justify-center items-center'>
                        <div className='w-10 h-10 border-2 border-t-2 border-gray-900 rounded-full animate-spin'></div>
                    </div>
                ) :
                    error ? (
                        <div className='w-full h-full flex justify-center items-center'>
                            <p className='text-red-600'>{JSON.stringify(error)}</p>
                        </div>
                    ) :
                        data?.length === 0 ? (
                            <div className='w-full h-full flex justify-center items-center'>
                                <p className='text-gray-600'>Please Buy Ebook to see coupons.</p>
                            </div>
                        ) : <div className='grid grid-cols-1 w-full'>
                            {data?.map((ebook: any, index: number) => (
                                <React.Fragment key={ebook._id}>
                                    {index % 1 === 0 && index !== bannersUrls?.length - 1 && index !== 0 && <img src={
                                       bannersUrls[(Math.floor(index / data?.length) % bannersUrls.length)]
                                    } className='w-auto lg:w-full mt-4 h-[100px] lg:h-auto' alt="" />}
                                    <div className='w-full border border-solid border-gray-200 rounded-2xl p-6 mt-6'>
                                        <EbookCard ebook={ebook} data={data} />
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>
            }
        </div>
    )
}


const EbookCard = ({
    ebook,
    data
}: {
    ebook: any,
    data: any
}) => {
    const navigate = useNavigate()

    const handleNavigate = (ebookId: string) => {
        let ebookData = data?.find((ebook: any) => ebook.ebook?._id === ebookId)
        navigate(`/user-panel/ebook/all-coupons`, {
            state: {
                data: ebookData
            }
        })
    }

    return (
        <div className='flex 2xl:flex-row xl:flex-row md:flex-row lg:flex-row flex-col gap-8 justify-between 2xl:items-center xl:items-center md:items-center lg:items-center items-start'>
            <div className='flex md:flex-row flex-col gap-6 lg:w-1/2'>
                <div className='2xl:w-36 xl:w-36 md:w-36 lg:w-36 w-full 2xl:h-20 xl:h-20 md:h-20 h-14 lg:h-auto flex justify-center items-center bg-primary rounded'>
                    <h1 className='2xl:text-sm xl:text-sm font-bold text-center text-xs 2xl:w-20 xl:w-20 md:w-20 lg:w-20 w-full'>
                        E-COUPON BOOK
                    </h1>
                </div>
                <div className='flex flex-col gap-1'>
                    <h4 className='font-medium text-lg'>
                        {"Save your money with our exclusive coupons"}
                    </h4>
                    <p className='text-gray-500 text-sm'>
                        {"Enjoy our exclusive coupons and save money on your favorite products and services."}
                    </p>
                    <div className='flex 2xl:flex-nowrap xl:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3'>
                        <p className='text-sm'>
                            Expire on: {moment(ebook.ebook?.expiryDate).format('DD MMM YYYY HH:mm')}
                        </p>
                        <div className='px-6 py-1 text-xs !text-white rounded-full bg-red-500'>
                            {ebook?.ads?.length} Coupons
                        </div>
                    </div>
                </div>
            </div>
            <button onClick={() => handleNavigate(ebook?.ebook?._id)} className='px-10 py-2.5 uppercase bg-primary rounded text-sm'>
                Get Coupon
            </button>
        </div>
    )
}