import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { getProductTax } from '../../../utils/utils';
import { useCreateShopifyOrderMutation } from '../../../features/api/products-api';
import { orderFormSchema } from '../../../features/schemas/order-schema';
import { useFormContext } from 'react-hook-form';
import { setCartLength } from '../../../features/slices/products-slice';
import { useToast } from '../../shared/ToastContext';

const CheckoutConfirmButton = () => {
    const { getValues, handleSubmit } = useFormContext();
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const params = useSearchParams();
    const [cart, setCart] = useLocalStorage('my_cart', []);
    const [isOkay, setIsOkay] = useState(false);
    const [createOrder, { isLoading, isSuccess, isError, data, error }] = useCreateShopifyOrderMutation();
    const { showToast } = useToast();
    const subtotal = 0
    const discount = 0
    let taxPrice = getProductTax(subtotal).toFixed(2);
    const total = Number(subtotal) + Number(taxPrice) - Number(discount);

    const formValues = getValues();
    useEffect(() => {
        if (isSuccess) {
            showToast("Order has been placed successfully", "success", "Your Magic club is created successfully. Select pool to join and win prizes.");
            setCart([...cart]);
            dispatch(setCartLength(0));
            // navigate(`/checkout/${data?.order?.id}`);
            navigate(`/join-pool`, {
                state: { order_value: total, secret: data?.order?.id },
            });
        }
        if (isError) {
        }
    }, [isSuccess, isError, error, dispatch, navigate, setCart, data?.order?.id]);

    const handleSubmitForm = () => {
        console.log('formValues', formValues);
        const cart = JSON.parse(localStorage.getItem('my_cart') || '[]');

        if (!cart || (cart && cart.length === 0)) {
            return showToast("Your cart is empty!", "error", "Please enter your cart");
        }
        if (!isOkay) {
            return showToast("Please accept the terms and conditions", "error", "Please accept Terms and Conditions.");
        }
        try {
            orderFormSchema.parse(formValues); // Validate form data against Zod schema
        } catch (error) {
            console.error('Validation error:');
            return;
        }

        let dataToSend = {
            ...formValues,
            cardCode: String(formValues.cardCode),
            billingAddress: {
                address1: formValues.address1,
                address2: formValues.address2 || 'abc',
                company: formValues.company,
                country: formValues.country,
                state: formValues.state,
                zip: formValues.zip,
                city: formValues.city,
            },
            isOtherAddress: formValues.isOtherAddress,
            shippingInfo: {
                address1: formValues.new_shipping.new_shipping_address1,
                address2: formValues.new_shipping.new_shipping_address2 || 'abc',
                company: formValues.new_shipping.new_shipping_company,
                country: formValues.new_shipping.new_shipping_country,
                state: formValues.new_shipping.new_shipping_state,
                zip: formValues.new_shipping.new_shipping_zip,
                city: formValues.new_shipping.new_shipping_city,
                firstName: formValues.new_shipping.new_shipping_firstname,
                lastName: formValues.new_shipping.new_shipping_lastname,
                phone: formValues.new_shipping.new_shipping_phone,
            },
            subtotal,
            tax: Number(taxPrice),
            shippingCharges: 0,
            discount,
            total,
            lineItems: cart.map((item: any) => ({
                variant_id: item?.variant_id, // TODO variant_id
                quantity: item?.quantity,
                product_id: item?.id,
                title: item?.title,
                price: Number(item?.price),
            })),
            newShippingAddress: formValues.isOtherAddress,
            referenceId: params.at(0) || null, //|| TODO
        };

        createOrder(dataToSend);
    };

    return (
        <div>
            <div className='my-5'>
                <label htmlFor='checkout_confirm' className='font-light'>
                    <input
                        type='checkbox'
                        id='checkout_confirm'
                        // label='Create an account?'
                        className='text-sm rounded'
                        // value={isOkay}
                        onClick={() => setIsOkay(!isOkay)}
                    />
                    <span className='ml-3'>
                        Your personal data will be used to process your order, support your experience throughout this website, and for
                        other purposes described in our privacy policy.
                    </span>
                </label>
            </div>
            <button
                type='button'
                disabled={isLoading}
                onClick={handleSubmit(handleSubmitForm)}
                className='bg-custom-yellow rounded w-full text-black font-semibold py-3'
            >
                {isLoading ? 'Processing...' : 'Place Order'}
            </button>
        </div>
    );
};

export default CheckoutConfirmButton;
