import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useLoginMutation } from "../../../features/slices/api-slices";
import { setToken } from "../../../features/slices/auth-slice";
import { useToast } from "../../shared/ToastContext";
import { PiEyeClosed, PiEyeClosedFill } from "react-icons/pi";

const schema = z.object({
    email: z.string().email("Invalid email address").nonempty("Email is required"),
    password: z.string().min(6, "Password must be at least 6 characters").nonempty("Password is required"),
});

type FormData = z.infer<typeof schema>;

const LoginForm: React.FC = () => {
    const dispatch = useDispatch();
    const { showToast } = useToast();
    const [login, { isLoading, error, data }] = useLoginMutation();
    const [params] = useSearchParams();
    const [showPassword, setshowPassword] = useState(false)
    const { register, handleSubmit, formState } = useForm<FormData>({
        resolver: zodResolver(schema),
        mode: "onBlur", // Validation mode onBlur
        defaultValues: {
            email: "",
            password: "",
        },
    });

    const togglePassword = useCallback(() => setshowPassword(prevState => !prevState), []);

    const { errors } = formState;

    const redirect_url = params.get("redirect");

    const onSubmit = async (data: FormData) => {
        await login(data);
    };

    useEffect(() => {
        if (error) {
            showToast(`Email and Password is incorrect`, "error", "Invalid email or password. Please try again.");
        }
        if (data && data.token) {
            localStorage.setItem("token", data.token);
            dispatch(setToken(data.token));
            if (redirect_url) {
                window.location.assign(redirect_url);
            } else {
                window.location.assign("/");
            }
        }
    }, [error, data, redirect_url, dispatch]);

    return (
        <div className="flex justify-between items-center h-screen w-full">
            <div className="w-full flex justify-center items-center">
                <div className="flex flex-col mx-auto gap-4 md:w-xxs w-full p-5 lg:p-10 md:shadow-lg rounded-xl">
                    <Link to="/" className="w-full flex justify-center items-center">
                        <img src={'/logo.gif'} alt="logo" className="w-smm h-xss object-cover"
                            width={150}
                            height={150}
                        />
                    </Link>
                    <h1 className="text-xl md:text-4xl font-bold tracking-wide capitalize my-2">Sign in</h1>
                    <form onSubmit={handleSubmit(onSubmit)} className="w-full flex flex-col gap-5">
                        <label htmlFor="email">
                            Email
                            <input
                                {...register("email")}
                                id="email"
                                className="rounded p-2 border border-gray-300 w-full"
                                type="email"
                            />
                        </label>
                        {errors.email && <span className="text-red-500 text-sm">{errors.email.message}</span>}

                        <div className="w-full relative">
                            <label htmlFor="password">
                                Password
                                <input
                                    {...register("password")}
                                    id="password"
                                    className="rounded p-2 border border-gray-300 w-full"
                                    type={showPassword ? "text" : "password"}
                                />
                            </label>
                            <button type="button" onClick={togglePassword} className="border-none outline-none bg-transparent absolute bottom-3 right-4 z-50">
                                {showPassword ? <PiEyeClosedFill /> : <PiEyeClosed />}
                            </button>
                        </div>
                        {errors.password && <span className="text-red-500 text-sm">{errors.password.message}</span>}

                        <button
                            disabled={isLoading}
                            type="submit"
                            className="bg-primaryRed text-white self-center rounded-full px-10 mt-5 py-3"
                        >
                            {isLoading ? "SIGNING IN..." : "SIGN IN"}
                        </button>
                        <div className="flex justify-center items-center gap-1">
                            <span className="text-gray-400">Don't have an account?</span>
                            <Link to="/auth/signup" className="text-primaryRed">
                                Sign up
                            </Link>
                        </div>
                        <div className="flex justify-center items-center gap-1">
                            <Link to="/auth/forgot-password" className="text-primaryRed">
                                Forgot Password
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
            <div className="hidden md:inline-flex">
                <img
                    src="/auth.png"
                    alt="auth"
                    className="h-screen w-xxl object-cover"
                    width={400}
                    height={400}
                />
            </div>
        </div>
    );
};

export default LoginForm;
