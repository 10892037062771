import commonApi from '../../redux/common-api';

export const poolApi = commonApi.injectEndpoints({
    endpoints: (builder) => ({
        createPool: builder.mutation({
            query: (pool) => ({
                url: `pool/create`,
                method: "POST",
                body: pool,
            }),
            invalidatesTags: ["pool"],
        }),
        getPools: builder.query({
            query: () => `pool`,
            providesTags: ["pool"],
        }),
        updatePool: builder.mutation({
            query: (pool) => ({
                url: `pool/update/${pool.id}`,
                method: "PUT",
                body: pool,
            }),
            invalidatesTags: ["pool"],
        }),
        closePool: builder.mutation({
            query: (id) => ({
                url: `pool/close/${id}`,
                method: "PUT",
            }),
            invalidatesTags: ["pool"],
        }),
        selectWinner: builder.mutation({
            query: (id) => ({
                url: `pool/select-winner/${id}`,
                method: "POST",
            }),
            invalidatesTags: ["pool"],
        }),
        joinPool: builder.mutation({
            query: (entery) => ({
                url: `pool-entry/enter`,
                method: "POST",
                body: entery,
            }),
            invalidatesTags: ["pool"],
        }),
        getPoolEntries: builder.query({
            query: (id) => `pool-entry/pool/${id}`,
            providesTags: ["pool"],
        }),
    }),
});

export const {
    useCreatePoolMutation,
    useGetPoolsQuery,
    useUpdatePoolMutation,
    useClosePoolMutation,
    useSelectWinnerMutation,
    useJoinPoolMutation,
    useGetPoolEntriesQuery,
} = poolApi;