import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoMdAdd, IoMdStar } from "react-icons/io";
import { RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../components/shared/ToastContext";
import { setCartLength } from "../../features/slices/products-slice";
import useLocalStorage from "../../hooks/useLocalStorage";
import { getCurrencySymbol } from "../../utils/utils";
import ProductVariants from "./product-variants";
import { FiMinus } from "react-icons/fi";

// import ProductVariants from "./ProductVariants";

interface ProductInfoProps {
    product: any;
    brand: string;
    title: string;
    ratings: number;
    price: string;
    reviews: any[];
    quantity: number;
    stock: number;
    handleIncrement: () => void;
    handleDecrement: () => void;
    setSelectedVariant: any;
    selectedVariant: any;
    isLoading: any;
}

const ProductInfo: React.FC<ProductInfoProps> = ({
    product,
    brand,
    title,
    price,
    isLoading,
    reviews,
    quantity,
    stock,
    handleIncrement,
    handleDecrement,
    setSelectedVariant
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [myVariant, setMyVariant] = useState<any>(null);
    const [cart, setCart] = useLocalStorage("my_cart", []);
    // const [addedToWishlist, setAddedToWishlist] = useState(myWishlist?.find((item) => item?.id === product?.id));
    const [addedToCart, setAddedToCart] = useState(cart?.find((item: any) => item.id === product?.id));
    const { currency, cartLength, token } = useSelector((state: RootState) => state.auth);
    const { showToast } = useToast();
    const handleAddToCart = (data: any) => {
        let variant = myVariant || product?.variants?.[0];
        let cart = JSON.parse(localStorage.getItem("my_cart") || "[]");
        if (!cart) {
            localStorage.setItem("my_cart", JSON.stringify([]));
            cart = [];
        }
        if (cart.find((item: any) => item?.id === data?.id)) {
            setAddedToCart(false);
            dispatch(setCartLength(cartLength - 1));
            setCart((prev: any[]) => prev.filter((item) => item?.id !== data?.id));
            return showToast("Your product was removed successfully", "success", "Product Removed");
        }

        console.log(data, product, variant, price);


        dispatch(setCartLength(cartLength + 1));
        setAddedToCart(true);
        let itemToAdd = {
            title: data?.title,
            id: data?.id,
            quantity: quantity,
            src: product?.images?.find((item: any) => item?.id === variant?.image_id)?.src || product?.images?.[0]?.src || product?.image?.src || product?.image,
            price: variant?.price || price,
            variant_id: variant?.id,
        }

        setCart([...cart, itemToAdd]);
        showToast("Your cart has been added successfully!", "success", "Product Added")
    }

    const handleBuyNow = () => {
        if (!token) {
            return navigate("/auth/signin?redirect=" + window.location.pathname);
        }
        handleAddToCart({
            id: product?.id,
            quantity: quantity,
            price: price,
            title: title,
            brand: brand,
            src: product?.image || product?.image?.src || product?.images?.[0]?.src || product?.images[0],
        });
        setTimeout(() => {
            navigate("/checkout");
        }, 2000);
    };
    // const handleWishList = (product: any) => {
    //     if (myWishlist?.find((item) => item?.id === product?.id)) {
    //         setAddedToWishlist(false);
    //         setMyWishlist((prev) => {
    //             return prev.filter((item) => item.id !== product.id);
    //         });
    //         return showToast("Your product removed from wishlist", "success", "Product Removed");
    //     }
    //     setAddedToWishlist(true);
    //     setMyWishlist((prev) => {
    //         return [...prev, product]
    //     });
    //     showToast("Product added to wishlist", "success", "Product Added");
    // }

    return (
        <div className="">
            <div className="flex flex-col gap-5 w-full max-h-min p-5">
                <div className="flex flex-col gap-2">
                    <h4 className='text-black 2xl:text-2xl xl:text-2xl 2xl:w-2/3 xl:w-2/3 leading-tight font-medium'>
                        {title}
                    </h4>
                    <div className='flex justify-between items-center 2xl:w-2/3 xl:2/3 w-full'>
                        <h4 className='text-2xl font-bold tracking-wide'>
                            {getCurrencySymbol(currency) + price}
                        </h4>
                        {product.rating ? <div className='bg-primary px-3 py-1 w-fit rounded-full text-sm flex items-center gap-2'>
                            5.0
                            <IoMdStar />
                        </div> : <p className='text-xs text-gray-500'>No rating</p>}
                    </div>
                    <h4 className='text-xl font-medium tracking-wide mt-4'>
                        Color
                    </h4>
                    <div className="w-full">
                        <ProductVariants product={product} setSelectedVariant={setSelectedVariant} handleSelectedVariant={setMyVariant} />
                    </div>
                    <div className='flex items-center gap-4 pt-7'>
                        <p>
                            Quantity:
                        </p>
                        <div className="relative">
                            <button
                                onClick={handleDecrement}
                                className="cursor-pointer font-extralight text-xs bg-gray-100 size-10 p-0 rounded-full flex justify-center items-center"
                            >
                                <FiMinus size={16} />
                            </button>
                        </div>
                        <input type='text' value={quantity ? quantity : 1} className="w-[100px] outline-none text-center h-11 px-3 bg-gray-100 rounded-lg border border-black/20" />
                        <button
                            onClick={handleIncrement}
                            className="cursor-pointer font-extralight text-xs bg-gray-100 size-10 p-0 rounded-full flex justify-center items-center"
                        >
                            <IoMdAdd size={16} />
                        </button>
                        {/* {addedToCart ? (
                            "Remove From Cart"
                        ) : stock < 1 ? (
                            <p className="text-xs text-gray-400">Out of stock</p>
                        ) : (
                            <p className="text-xs text-gray-400">Add to Cart</p>
                        )} */}
                    </div>
                    <p className='mt-6 font-normal'>
                        Discount Coupon:
                    </p>
                    <p className='text-lg font-medium mt-3'>
                        Apply Coupon: Extra 15% off with code BAG15
                    </p>
                    <div className='flex items-center gap-6 pt-10 2xl:w-2/3 xl:2/3 w-full'>
                        <button type="button" onClick={handleBuyNow} className='w-full 2xl:py-4 xl:py-4 md:py-4 lg:py-4 py-2 bg-primary text-sm rounded-full'>
                            Buy Now
                        </button>
                        <button type="button" disabled={stock < 1}
                            onClick={() =>
                                handleAddToCart({
                                    id: product?.id,
                                    quantity: quantity,
                                    price: price,
                                    title: title,
                                    brand: brand,
                                    src: product?.image || product?.image?.src || product?.images?.[0]?.src || product?.images[0],
                                })
                            } className='w-full 2xl:py-4 xl:py-4 md:py-4 lg:py-4 py-2 border border-solid border-gray-700 text-sm rounded-full'>
                            {
                                addedToCart ? "Remove from Cart" : "Add to Cart"
                            }
                        </button>
                    </div>
                    {/* <div className="flex justify-center items-center gap-4 whitespace-nowrap mt-5">
                        <span
                            onClick={() => handleWishList(product)}
                            className="flex justify-center items-center text-sm gap-1 cursor-pointer"
                        >
                            <AiOutlineHeart size={20} color={addedToWishlist ? "red" : ""} />
                            <span className="text-md font-light hidden md:block">Wishlist</span>
                        </span>
                        <div className="h-full border-gray-300 border" />
                        <span className="flex justify-center items-center text-sm gap-1 cursor-pointer">
                            <HiOutlineChartBar size={20} />
                            <span className="text-md font-light hidden md:block ml-2">Compare</span>
                        </span>
                        <div className="h-full border-gray-300 border" />
                        <span className="flex justify-center items-center text-sm gap-1 cursor-pointer">
                            <BsPatchQuestion size={20} />
                            <span className="text-md font-light ml-2 hidden md:block">Ask a question</span>
                        </span>
                        <div className="h-full border-gray-300 border" />
                        <span className="flex justify-center items-center text-sm gap-1 cursor-pointer">
                            <IoShareSocialOutline size={20} />
                            <span className="text-md font-light ml-2 hidden md:block">Share</span>
                        </span>
                    </div> */}
                    {/* <div className="flex gap-2 flex-col">
                        <div className="flex justify-betwee items-center h-full">
                            <LiaShippingFastSolid size={25} />
                            <div className="ml-3">
                                <span className="text-md font-light">Free shipping, arrives by { } to Sacramento, 95829</span>
                                <br />
                                <span className="text-md font-light">Want it faster? Add an address More options</span>
                            </div>
                        </div>
                        <div className="flex justify-betwee items-center">
                            <LiaBoxOpenSolid size={25} />
                            <div className="ml-3">
                                <span className="text-md font-light">Sold and shipped by </span>
                                <br />
                                <span className="text-md font-light">Damndeal</span>
                            </div>
                        </div>
                        <div className="flex justify-betwee items-center">
                            <LiaBusinessTimeSolid size={25} />
                            <span className="text-medium ml-2 font-light">Free 15-Day returns Details</span>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default ProductInfo;