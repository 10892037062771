import React from 'react'
// import Swiper core and required modules

import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import { useGetBusinessesQuery } from '../../../../features/slices/vendor-api';
export default function BestSellerCategories() {
    const { data, isLoading } = useGetBusinessesQuery()
    return (
        <>
            <div className='bg-black w-full py-8 mt-8'>
                <div className='flex justify-center'>
                    <h3 className='text-xl font-bold text-white'>
                        Best Sellers
                    </h3>
                </div>
                <div className='max-w-screen-2xl mx-auto 2xl:px-0 px-3 pt-7'>
                    <Swiper
                        // install Swiper modules
                        spaceBetween={30}
                        slidesPerView={8}
                        className='text-white'
                        breakpoints={{
                            0: {
                                slidesPerView: 3,
                            },
                            400: {
                                slidesPerView: 4,
                            },
                            639: {
                                slidesPerView: 4,
                            },
                            865: {
                                slidesPerView: 4
                            },
                            1000: {
                                slidesPerView: 5
                            },
                            1500: {
                                slidesPerView: 8
                            },
                            1700: {
                                slidesPerView: 8
                            }
                        }}
                    >
                        {
                            isLoading ? Array(8).fill(0)?.map((item: number, index: number) => (
                                <div key={index} className='flex gap-4 items-center'>
                                    <div className='flex justify-center items-center 2xl:size-20 xl:size-20 size-14 bg-white p-2 rounded-full'>
                                        <div className='w-full h-full rounded-full animate-pulse bg-gray-300' />
                                    </div>
                                    <div className='text-center 2xl:text-sm xl:text-sm text-xs font-medium'>
                                        <div className='w-20 h-3 bg-gray-300 animate-pulse rounded-full' />
                                    </div>
                                </div>
                            ))
                                : data?.slice(0, 12)?.map((item: any) => (
                                    <SwiperSlide key={item.businessName}>
                                        <Link to={`/business-profile/${item?._id}`} className='flex flex-col gap-4 items-center'>
                                            <div className='flex justify-center items-center 2xl:size-20 xl:size-20 size-14 bg-white p-0 rounded-full'>
                                                {
                                                    // profile image or first character of name
                                                    item?.profile ?
                                                        <img loading='lazy' src={item.profile} alt={item.businessName} className='w-full h-full rounded-full' />
                                                        :
                                                        <span className='text-black font-bold text-2xl'>{item?.businessName?.substr(0, 2)?.toUpperCase()}</span>
                                                }
                                            </div>
                                            <div className='text-center 2xl:text-sm xl:text-sm text-xs font-medium'>
                                                {item?.businessName?.slice(0, 30)}
                                            </div>
                                        </Link>
                                    </SwiperSlide>
                                ))}

                    </Swiper>
                </div>
            </div>
        </>
    )
}
