import { useEffect } from 'react';
import { ENV } from '../utils/utils';

// Define the types for the event parameters
interface EventParams {
    event_category?: string;
    event_label?: string;
    value?: number;
    [key: string]: any; // Allows for additional custom parameters
}

const useGoogleAnalytics = () => {
    let trackingId = process.env.REACT_APP_GA_TRACKING_ID || "G-C04Q7MV7FK"
    // Load the Google Analytics script when the component mounts
    useEffect(() => {
        // Only add the script in production
        if (ENV === 'production') {
            // Add the gtag.js script
            const script = document.createElement('script');
            script.async = true;
            script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
            document.head.appendChild(script);

            // Add the inline script to initialize gtag
            const inlineScript = document.createElement('script');
            inlineScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${trackingId}');
      `;
            document.head.appendChild(inlineScript);
        }
    }, [trackingId]);

    // Function to track page views
    const trackPageView = (url: string) => {
        if (ENV === 'production' && (window as any).gtag) {
            (window as any).gtag('event', 'page_view', {
                page_path: url,
            });
        }
    };

    // Function to track custom events
    const trackEvent = (eventName: string, params: EventParams) => {
        if (ENV === 'production' && (window as any).gtag) {
            (window as any).gtag('event', eventName, params);
        }
    };

    // Return the tracking functions for use in components
    return {
        trackPageView,
        trackEvent,
    };
};

export default useGoogleAnalytics;
