import { useFormContext } from 'react-hook-form';
import { MdKeyboardArrowDown } from 'react-icons/md';

const SocialProfile = ({ platform, imgSrc, placeholder }: {
    platform: string;
    imgSrc: string;
    placeholder: string;
}) => {
    const { register, formState: { errors } } = useFormContext();
    return <div className="grid 2xl:grid-cols-2 xl:grid-cols-2 grid-cols-1 gap-8 pt-8">
        <div className="w-full relative">
            <div className="w-full border border-solid border-gray-200 cursor-pointer rounded-md outline-none h-14 px-4">
                <div className="flex justify-between items-center">
                    <div className="flex items-center h-14 gap-2">
                        {/* <img loading="lazy" src={imgSrc} alt={`${platform} icon`} /> */}
                        <label className="text-sm bg-white font-normal">{platform}</label>
                    </div>
                    {/* <MdKeyboardArrowDown className="text-xl" /> */}
                </div>
            </div>
        </div>
        <div className="w-full relative">
            <input
                {...register(platform, { required: true })}
                type="text"
                placeholder={placeholder}
                className="w-full border border-solid border-gray-200 rounded-md outline-none h-14 px-4"
            />
        </div>
    </div>
}

const SocialProfileLinks = () => {
    const profiles = [
        { platform: 'facebook', imgSrc: '/assets/images/facebook.png', placeholder: 'Add Facebook link...' },
        { platform: 'twitter', imgSrc: '/assets/images/twitter.png', placeholder: 'Add Twitter link...' },
        { platform: 'instagram', imgSrc: '/assets/images/instagram.png', placeholder: 'Add Instagram link...' }
    ];

    return (
        <div className="w-full bg-white p-8 pt-5 mt-10 rounded-3xl">
            <div className="flex justify-between items-center">
                <h2 className="text-black text-[22px] font-medium mt-6 leading-snug">Social Profile Links</h2>
            </div>
            {profiles.map((profile, index) => (
                <SocialProfile
                    key={index}
                    platform={profile.platform}
                    imgSrc={profile.imgSrc}
                    placeholder={profile.placeholder}
                />
            ))}
        </div>
    );
};

export default SocialProfileLinks;
