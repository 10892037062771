import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules'
import 'swiper/css';

export default function BannerSlider() {
    const banners = ["https://res.cloudinary.com/dgnve8m5k/image/upload/v1725857751/assets/banners/damndeal-earn-with-spending.png", "https://res.cloudinary.com/dgnve8m5k/image/upload/v1725857752/assets/banners/magic-pools-join-damndeal.png"]
    return (
        <>
            <main className="max-w-screen-2xl mx-auto 2xl:mt-8 xl:mt-8 md:mt-8 lg:mt-8 mt-3 relative 2xl:px-0 xl:px-16 px-3">
                <Swiper
                    spaceBetween={50}
                    slidesPerView={1}
                    autoplay={{
                        delay: 2000,
                        disableOnInteraction: false
                    }}
                    modules={[Autoplay]}
                    speed={2000}
                    loop={true}
                >
                    {
                        banners.map((banner, index) => (
                            <SwiperSlide>
                                <img key={index} src={banner} className='rounded-2xl' alt="damndeal magic club" />
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
                {/* <button type="button" className='2xl:size-12 xl:size-12 md:size-12 lg:size-12 size-6 bg-white rounded-full flex justify-center items-center absolute 2xl:top-28 xl:top-24 top-6 2xl:left-10 xl:left-20 left-10 z-50'>
                    <MdOutlineKeyboardArrowLeft size={24} />
                </button>
                <button type="button" className='2xl:size-12 xl:size-12 md:size-12 lg:size-12 size-6 bg-white rounded-full flex justify-center items-center absolute 2xl:top-28 xl:top-24 top-6 2xl:right-10 xl:right-20 right-10 z-50'>
                    <MdOutlineKeyboardArrowRight size={24} />
                </button> */}
            </main>
        </>
    )
}
